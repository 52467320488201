import { gql } from "@apollo/client";

/**
 * 🔹 Customer GraphQL Fragments
 * - These fragments define reusable field selections for customers.
 * - Used to ensure that only necessary data is fetched dynamically.
 */

export const CUSTOMER_FRAGMENTS = {
  id: gql`
    fragment CustomerId on Customer {
      id
    }
  `,
  name: gql`
    fragment CustomerName on Customer {
      id
      firstName
      lastName
    }
  `,
  email: gql`
    fragment CustomerEmail on Customer {
      id
      email
    }
  `,
  phone: gql`
    fragment CustomerPhone on Customer {
      id
      phone
    }
  `,
  site: gql`
    fragment CustomerSite on Customer {
      id
      connectedSites {
        id
        location {
          streetOne
          streetTwo
          city
          state
          postalCode
        }
      }
    }
  `,
  timestamps: gql`
    fragment CustomerTimestamps on Customer {
      id
      createdAt
      updatedAt
    }
  `,
};
