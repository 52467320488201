import React from "react";
import { twMerge } from "tailwind-merge";
import { Icon } from "../Icon/Icon";
interface TagProps {
  label: string;
  className?: string;
  variant?: "info" | "success" | "warning" | "error";
  size?: "sm" | "md";
  onDismiss?: () => void;
  error?: string;
}
export const Tag: React.FC<TagProps> = ({
  label,
  className,
  variant = "info",
  size = "sm",
  onDismiss,
  error
}) => {
  const variantStyles = {
    info: "bg-feedback-info-background text-feedback-info-text",
    success: "bg-feedback-success-background text-feedback-success-text",
    warning: "bg-feedback-warning-background text-feedback-warning-text",
    error: "bg-feedback-error-background text-feedback-error-text"
  };
  const sizeStyles = {
    sm: "px-2 py-0.5 text-xs gap-1.5",
    md: "px-3 py-1 text-sm gap-2"
  };
  return <div className="flex flex-col gap-1" data-sentry-component="Tag" data-sentry-source-file="Tag.tsx">
      <div className={twMerge("inline-flex items-center rounded-full font-medium", sizeStyles[size], variantStyles[error ? "error" : variant], className)}>
        <span>{label}</span>
        {onDismiss && <button onClick={onDismiss} className="text-current opacity-60 hover:opacity-100" aria-label="Remove">
            <Icon name="X" size={size === "sm" ? 12 : 14} />
          </button>}
      </div>
      {error && <span className="text-xs text-feedback-error-text">{error}</span>}
    </div>;
};