import { FieldDefinition } from "@/types/fields";
import { TimestampCell } from "@/components/edges/ui/TimestampCell";
import { Role } from "@/@codegen/supergraph/graphql";
import InviteMenu from "@/app/(dashboard)/settings/organization/components/OrganizationMembersTable/components/InviteMenu";
import { useLoggedUser } from "@/state/user/useLoggedUser";
import { useSelectedOrganization } from "@/state/user/useSelectedOrganization";
import { TagCell } from "@/components/edges/ui/TagCell";
import { BaseCell } from "@/components/edges/ui/BaseCell";
import { Tag } from "@/components/edges/ui/Tag";
import { textTransforms } from "@/utils/formatting";
import { useOrganizationService } from "@/services/organizationService";
import useLoggedUserFunctions from "@/hooks/useLoggedUserFunctions";
import { toast } from "react-hot-toast";
const MEMBER_STATUS_VARIANT_MAP: Record<string, "success" | "warning" | "error"> = {
  ACCEPTED: "success",
  PENDING: "warning",
  REVOKED: "error"
};
export const ORGANIZATION_MEMBERS_RESPONSE_PATH = ["organizationMembers", "data"];
export interface OrganizationMembersFilter {
  organizationId: string;
}
export interface OrganizationMembersInput {
  filter?: OrganizationMembersFilter;
  pagination?: {
    offset?: {
      page: number;
      perPage: number;
    };
  };
  sort?: string;
}
export interface MembershipRow extends Record<string, unknown> {
  id: string;
  role: Role;
  isAdmin: boolean;
  createdAt: string;
  updatedAt: string;
  user: {
    id: string;
    profilePhoto?: string | null;
    fullName?: string | null;
    emails?: Array<{
      email: string;
      domain: string;
    } | null> | null;
  };
  organization: {
    id: string;
    name: string;
    domain?: string | null;
  };
}
const getMemberRoles = (member: MembershipRow): Role[] => {
  const roles = [member.role];
  if (member.isAdmin) {
    roles.push(Role.Admin);
  }
  return roles;
};
export const organizationMembersFields: Array<FieldDefinition<MembershipRow>> = [{
  name: "id",
  label: "Member ID",
  dataType: "String",
  entityType: "OrganizationMembership",
  format: {
    type: "text",
    transform: "lowercase"
  },
  tableControl: {
    getValue: row => row.id,
    isSearchable: true
  },
  dataSources: [{
    type: "list",
    entityType: "OrganizationMembership",
    fragmentKey: "id",
    responsePath: ORGANIZATION_MEMBERS_RESPONSE_PATH
  }]
}, {
  name: "name",
  label: "Name",
  dataType: "String",
  entityType: "OrganizationMembership",
  format: {
    type: "text",
    transform: "capitalize"
  },
  tableControl: {
    getValue: row => row.user?.fullName ?? "",
    isSearchable: true
  },
  dataSources: [{
    type: "list",
    entityType: "OrganizationMembership",
    fragmentKey: "user",
    responsePath: ORGANIZATION_MEMBERS_RESPONSE_PATH
  }]
}, {
  name: "email",
  label: "Email",
  dataType: "String",
  entityType: "OrganizationMembership",
  format: {
    type: "text",
    transform: "lowercase",
    nullText: ""
  },
  display: {
    link: {
      type: "email",
      getHref: value => `mailto:${value as string}`
    }
  },
  tableControl: {
    getValue: row => {
      const primaryEmail = row.user?.emails?.[0]?.email;
      return primaryEmail ?? "";
    },
    isSearchable: true
  },
  dataSources: [{
    type: "list",
    entityType: "OrganizationMembership",
    fragmentKey: "user",
    responsePath: ORGANIZATION_MEMBERS_RESPONSE_PATH
  }]
}, {
  name: "roles",
  label: "Roles",
  dataType: "String",
  entityType: "OrganizationMembership",
  format: {
    type: "text",
    transform: "titleCase"
  },
  tableControl: {
    getValue: row => getMemberRoles(row).join(","),
    render: (row, {
      isLoading
    }) => {
      return <TagCell tags={getMemberRoles(row).map(role => textTransforms.titleCase(String(role)))} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "OrganizationMembership",
    fragmentKey: "role",
    responsePath: ORGANIZATION_MEMBERS_RESPONSE_PATH
  }]
}, {
  name: "status",
  label: "Status",
  dataType: "String",
  entityType: "OrganizationMembership",
  format: {
    type: "text",
    transform: "titleCase"
  },
  tableControl: {
    getValue: () => "ACCEPTED",
    render: (row, {
      isLoading
    }) => {
      // Memberships are always ACCEPTED
      const status = "ACCEPTED";
      return <BaseCell isLoading={isLoading}>
              <Tag label={textTransforms.titleCase(status)} variant={MEMBER_STATUS_VARIANT_MAP[status] ?? "info"} />
            </BaseCell>;
    },
    width: "1fr"
  },
  dataSources: [{
    type: "list",
    entityType: "OrganizationMembership",
    fragmentKey: "id",
    // Using id instead of status since status doesn't exist
    responsePath: ORGANIZATION_MEMBERS_RESPONSE_PATH
  }]
}, {
  name: "isAdmin",
  label: "Admin",
  dataType: "Boolean",
  entityType: "OrganizationMembership",
  format: {
    type: "boolean"
  },
  tableControl: {
    getValue: row => Boolean(row?.isAdmin).toString(),
    isSearchable: true
  },
  dataSources: [{
    type: "list",
    entityType: "OrganizationMembership",
    fragmentKey: "isAdmin",
    responsePath: ORGANIZATION_MEMBERS_RESPONSE_PATH
  }]
}, {
  name: "createdAt",
  label: "Created",
  dataType: "Date",
  entityType: "OrganizationMembership",
  format: {
    type: "date",
    style: "medium",
    relative: true
  },
  tableControl: {
    getValue: row => row.createdAt ?? "",
    render: (row, {
      isLoading
    }) => <TimestampCell value={row.createdAt ?? ""} isLoading={isLoading} />,
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "OrganizationMembership",
    fragmentKey: "timestamps",
    responsePath: ORGANIZATION_MEMBERS_RESPONSE_PATH
  }]
}, {
  name: "updatedAt",
  label: "Updated",
  dataType: "Date",
  entityType: "OrganizationMembership",
  format: {
    type: "date",
    style: "medium",
    relative: true
  },
  tableControl: {
    getValue: row => row.updatedAt ?? "",
    render: (row, {
      isLoading
    }) => <TimestampCell value={row.updatedAt ?? ""} isLoading={isLoading} />,
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "OrganizationMembership",
    fragmentKey: "timestamps",
    responsePath: ORGANIZATION_MEMBERS_RESPONSE_PATH
  }]
}, {
  name: "actions",
  label: "Actions",
  dataType: "String",
  entityType: "OrganizationMembership",
  tableControl: {
    getValue: () => "",
    render: (row, {
      isLoading
    }) => {
      if (isLoading) return null;
      const {
        loggedUser
      } = useLoggedUser();
      const {
        selectedOrganization
      } = useSelectedOrganization();
      const {
        deleteMembership
      } = useOrganizationService();
      const {
        isAdminOfOrganization
      } = useLoggedUserFunctions();
      const isAdmin = isAdminOfOrganization(selectedOrganization);
      if (!row.user || row.user.id === loggedUser?.id) {
        return null;
      }
      const handleDeleteMembership = async (id: string) => {
        return deleteMembership(id, selectedOrganization?.id).then(() => {
          toast.success("Member removed successfully");
        }).catch((error: Error) => {
          console.error(error);
          toast.error("Error deleting member");
        });
      };
      return <InviteMenu status="ACCEPTED" email={row.user.emails?.[0]?.email ?? ""} membershipId={row.id} userName={row.user.fullName ?? undefined} deleteMembership={handleDeleteMembership} isAdmin={isAdmin} />;
    },
    width: "80px"
  },
  dataSources: [{
    type: "list",
    entityType: "OrganizationMembership",
    fragmentKey: "id",
    responsePath: ORGANIZATION_MEMBERS_RESPONSE_PATH
  }]
}];