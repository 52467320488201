import { gql } from "@apollo/client";

export const INVITATION_FRAGMENTS = {
  id: gql`
    fragment InvitationId on Invitation {
      __typename
      id
      organizationId
      organizationName
    }
  `,
  email: gql`
    fragment InvitationEmail on Invitation {
      __typename
      email
      userExists
      userId
    }
  `,
  role: gql`
    fragment InvitationRole on Invitation {
      __typename
      role
    }
  `,
  status: gql`
    fragment InvitationStatus on Invitation {
      __typename
      status
    }
  `,
  timestamps: gql`
    fragment InvitationTimestamps on Invitation {
      __typename
      createdAt
      updatedAt
      sentAt
    }
  `,
  invitedBy: gql`
    fragment InvitationInvitedBy on Invitation {
      __typename
      invitedByProfile {
        __typename
        id
        fullName
      }
    }
  `,
} as const;
