import React from "react";
import { PrefixedBaseCell, BaseCellProps } from "@/edges/ui/BaseCell";
import { DeviceState } from "../../ui/DeviceState/DeviceState";
import type { DeviceState as DeviceStateType } from "../../types/DeviceStates";
import type { GridState } from "../../types/GridStates";
import { deviceStateMetricFormats } from "../../types/DeviceStates";
import { toPercentage, tokW, toTemperature, TemperatureUnitSymbol } from "@/utils/string";
import { twMerge } from "tailwind-merge";
import useLoggedUserFunctions from "@/hooks/useLoggedUserFunctions";
import { Skeleton } from "@/edges/ui/Skeleton/Skeleton";

// Helper function to format metric values based on device state format
const formatMetric = (value: number | string, state: DeviceStateType, preferredTemperatureUnit?: TemperatureUnitSymbol, overrideFormat?: "percentage" | "temperature" | "amps" | "kW"): string => {
  if (typeof value === "number") {
    const format = overrideFormat || deviceStateMetricFormats[state];
    if (!format) return value.toString();
    switch (format) {
      case "percentage":
        return toPercentage(value) ?? "—";
      case "kW":
        return tokW(value) ?? "—";
      case "temperature":
        return toTemperature(value, preferredTemperatureUnit) ?? "—";
      case "amps":
        return `${value}A`;
      default:
        return value.toString();
    }
  }
  return value.toString();
};
type DeviceStateCellProps = Omit<BaseCellProps, "children" | "loadingContent"> & {
  state: DeviceStateType;
  gridStatus?: GridState;
  metric?: number | string;
  metricFormat?: "percentage" | "temperature" | "amps" | "kW";
  className?: string;
};
export const DeviceStateCell: React.FC<DeviceStateCellProps> = ({
  state,
  gridStatus,
  metric,
  metricFormat,
  className,
  ...props
}) => {
  const {
    getPreferredTemperatureUnit
  } = useLoggedUserFunctions();
  const preferredTemperatureUnit = getPreferredTemperatureUnit({
    asSymbol: true
  });
  const formattedMetric = metric !== undefined ? formatMetric(metric, state, preferredTemperatureUnit, metricFormat) : "—";
  const content = <div className={twMerge("flex items-center gap-2", className)}>
      <div className="w-16 text-left">
        <span className="text-textPrimary dark:text-textPrimaryDark text-sm">
          {formattedMetric}
        </span>
      </div>
      <div className="flex gap-2">
        <DeviceState state={state} />
        {gridStatus && <DeviceState state={gridStatus} />}
      </div>
    </div>;
  const loadingContent = <div className={twMerge("flex items-center gap-2", className)}>
      <div className="w-16 text-left">
        <Skeleton width={36} height={16} />
      </div>
      <div className="flex gap-2">
        <Skeleton width={120} height={16} />
        <Skeleton width={120} height={16} />
      </div>
    </div>;
  return <PrefixedBaseCell {...props} loadingContent={loadingContent} data-sentry-element="PrefixedBaseCell" data-sentry-component="DeviceStateCell" data-sentry-source-file="DeviceStateCell.tsx">
      {content}
    </PrefixedBaseCell>;
};