import { DateRange } from "@/utils/date";
import { WindowSize } from "@/@codegen/supergraph";
import type { FieldDefinition } from "@/types/fields";
import type { TimeSeriesDataSource as BaseTimeSeriesDataSource } from "@/types/fields/dataSources/TimeSeriesDataSource";
import type { DeviceStateHistoryDataSource as BaseDeviceStateHistoryDataSource } from "@/types/fields/dataSources/DeviceStateHistoryDataSource";

// Default to last 24 hours
export const DEFAULT_DATE_RANGE: DateRange = {
  after: new Date(Date.now() - 24 * 60 * 60 * 1000),
  before: new Date(),
};

// Regular time series query params (for metrics)
export interface TimeSeriesQueryParams {
  filter: {
    siteId?: string;
    deviceId?: string;
    workspaceId?: string;
    type?: string;
    range?: DateRange;
  };
  window: {
    size: WindowSize;
  };
}

export const DEFAULT_TIME_SERIES_QUERY_PARAMS: TimeSeriesQueryParams = {
  filter: {
    range: DEFAULT_DATE_RANGE,
  },
  window: {
    size: WindowSize.Hour,
  },
};

// Device state history query params
export interface DeviceStateHistoryQueryParams {
  filter: {
    id: string;
    from: string;
    to?: string;
  };
  pagination?: {
    offset: {
      perPage: number;
    };
  };
}

export const DEFAULT_DEVICE_STATE_HISTORY_QUERY_PARAMS: DeviceStateHistoryQueryParams =
  {
    filter: {
      id: "${id}",
      from: DEFAULT_DATE_RANGE.after.toISOString(),
      to: DEFAULT_DATE_RANGE.before.toISOString(),
    },
    pagination: {
      offset: {
        perPage: 1000,
      },
    },
  };

// Helper to create a time series data source
export function createTimeSeriesDataSource<T>(
  timeField: string,
  valueField: string,
  options: Partial<BaseTimeSeriesDataSource<T>> = {},
): BaseTimeSeriesDataSource<T> {
  return {
    type: "timeSeries",
    entityType: "device",
    responsePath: ["energyEmissions", "data"],
    timeField,
    valueField,
    fragmentKey: "energyEmissions",
    queryParams: DEFAULT_TIME_SERIES_QUERY_PARAMS,
    ...options,
  };
}

// Helper to create a device state history data source
export function createDeviceStateHistoryDataSource<T>(
  valueField: string,
  options: Partial<BaseDeviceStateHistoryDataSource<T>> = {},
): BaseDeviceStateHistoryDataSource<T> {
  return {
    type: "timeSeries",
    entityType: "device",
    responsePath: ["deviceStateHistory", "data"],
    timeField: "createdAt",
    valueField,
    fragmentKey: "deviceStateHistory",
    queryParams: DEFAULT_DEVICE_STATE_HISTORY_QUERY_PARAMS,
    isDeviceStateHistory: true,
    ...options,
  };
}

// Helper to check if a field has a time series data source
export function hasTimeSeriesDataSource<T>(
  field: FieldDefinition<T>,
): field is FieldDefinition<T> & {
  dataSources: BaseTimeSeriesDataSource<T>[];
} {
  return (
    field.dataSources?.some(
      (source): source is BaseTimeSeriesDataSource<T> =>
        source.type === "timeSeries" && !("isDeviceStateHistory" in source),
    ) ?? false
  );
}

// Helper to check if a field has a device state history data source
export function hasDeviceStateHistoryDataSource<T>(
  field: FieldDefinition<T>,
): field is FieldDefinition<T> & {
  dataSources: BaseDeviceStateHistoryDataSource<T>[];
} {
  return (
    field.dataSources?.some(
      (source): source is BaseDeviceStateHistoryDataSource<T> =>
        source.type === "timeSeries" && "isDeviceStateHistory" in source,
    ) ?? false
  );
}
