import { FormatRegistry, FormatType } from "@/utils/string";
import { PrefixedBaseCell, BaseCellProps } from "../BaseCell";
type TextCellProps = BaseCellProps & {
  format?: FormatType;
  formatArgs?: unknown[];
  value?: string;
};
export const TextCell: React.FC<TextCellProps> = ({
  value,
  format,
  formatArgs = [],
  ...props
}) => {
  const formattedValue = format ? FormatRegistry.format(format, value, ...formatArgs) : value;

  // Special case for phone numbers - override href to use tel: protocol
  const finalHref = format === "phoneNumber" && value ? `tel:${value}` : props.href;
  return <PrefixedBaseCell {...props} href={finalHref} data-sentry-element="PrefixedBaseCell" data-sentry-component="TextCell" data-sentry-source-file="TextCell.tsx">
      {formattedValue}
    </PrefixedBaseCell>;
};