export type DeviceState =
  | "unknown"
  | "on"
  | "off"
  | "idle"
  | "charging"
  | "discharging"
  | "heat"
  | "eco"
  | "cool"
  | "auto";

export type MetricFormat = "percentage" | "kW" | "temperature";

export const deviceStateMetricFormats: Record<
  DeviceState,
  MetricFormat | undefined
> = {
  charging: "percentage",
  discharging: "percentage",
  on: "kW",
  idle: undefined,
  off: undefined,
  heat: "temperature",
  cool: "temperature",
  auto: "temperature",
  eco: "temperature",
  unknown: undefined,
} as const;
