import { gql } from "@apollo/client";

export const MEMBERSHIP_FRAGMENTS = {
  id: gql`
    fragment MembershipId on Membership {
      id
    }
  `,
  user: gql`
    fragment MembershipUser on Membership {
      user {
        id
        profilePhoto
        fullName
        emails {
          email
          domain
        }
      }
    }
  `,
  role: gql`
    fragment MembershipRole on Membership {
      role
    }
  `,
  isAdmin: gql`
    fragment MembershipIsAdmin on Membership {
      isAdmin
    }
  `,
  timestamps: gql`
    fragment MembershipTimestamps on Membership {
      createdAt
      updatedAt
    }
  `,
} as const;
