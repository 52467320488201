import { twMerge } from "tailwind-merge";
import { TextLink } from "@/edges/ui/TextLink";
import { CellAlignment } from "../DataTable";
import { Skeleton } from "../Skeleton/Skeleton";
export type BaseCellProps = {
  className?: string;
  containerClassName?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  width?: string;
  dataTestId?: string;
  truncate?: boolean;
  href?: string;
  isPrimaryCell?: boolean;
  title?: string;
  align?: CellAlignment;
  isLoading?: boolean;
  loadingContent?: React.ReactNode;
};
export const BaseCell: React.FC<BaseCellProps> = ({
  className,
  containerClassName,
  disabled,
  children,
  width,
  dataTestId,
  truncate = false,
  href,
  isPrimaryCell,
  title,
  align,
  isLoading,
  loadingContent
}) => {
  const content = children || "—";
  const contentClassName = twMerge("truncate", isPrimaryCell && "font-semibold", href && "hover:underline hover:decoration-1 hover:underline-offset-4 hover:text-action-primary");
  const defaultLoadingContent = <Skeleton width={isPrimaryCell ? 180 : 120} height={16} />;
  const wrappedContent = isLoading ? loadingContent ?? defaultLoadingContent : href ? <TextLink href={href} className={contentClassName} title={title}>
      {content}
    </TextLink> : <span className={contentClassName} title={title}>
      {content}
    </span>;
  return <div data-testid={dataTestId} className={twMerge("flex h-full items-center py-2", align === "center" && "justify-center", align === "right" && "justify-end", containerClassName, disabled && "cursor-not-allowed opacity-50", width && `w-[${width}]`, className, truncate && "max-w-full truncate")} data-sentry-component="BaseCell" data-sentry-source-file="BaseCell.tsx">
      {wrappedContent}
    </div>;
};