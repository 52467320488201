import React from "react";
import { PrefixedBaseCell, BaseCellProps } from "@/edges/ui/BaseCell";
import { Tag } from "@/edges/ui/Tag";
import { Skeleton } from "@/edges/ui/Skeleton/Skeleton";
import { twMerge } from "tailwind-merge";
type TagVariant = "success" | "warning" | "error" | "info";
type TagItem = {
  label: string;
  variant?: TagVariant;
};
type TagCellProps = Omit<BaseCellProps, "children" | "loadingContent"> & {
  tags?: (string | TagItem)[];
  variant?: TagVariant;
  className?: string;
  isLoading?: boolean;
};
export const TagCell: React.FC<TagCellProps> = ({
  tags = [],
  variant = "info",
  className,
  isLoading,
  ...props
}) => {
  const loadingContent = <div className={twMerge("flex flex-wrap gap-1", className)}>
      <Skeleton width={60} height={16} />
      <Skeleton width={80} height={16} />
    </div>;

  // Show loading state regardless of tags
  if (isLoading) {
    return <PrefixedBaseCell loadingContent={loadingContent} isLoading={true} {...props}>
        <div className={twMerge("flex flex-wrap gap-1", className)}>
          {tags?.map((tag, index) => {
          if (!tag) return null;
          const tagLabel = typeof tag === "string" ? tag : tag.label;
          const tagVariant = typeof tag === "string" ? variant : tag.variant ?? variant;
          return <Tag key={`${tagLabel}-${index}`} label={tagLabel} variant={tagVariant} />;
        })}
        </div>
      </PrefixedBaseCell>;
  }

  // Only check for empty tags after loading check
  if (!tags || tags.length === 0) {
    return null;
  }
  return <PrefixedBaseCell loadingContent={loadingContent} {...props} data-sentry-element="PrefixedBaseCell" data-sentry-component="TagCell" data-sentry-source-file="TagCell.tsx">
      <div className={twMerge("flex flex-wrap gap-1", className)}>
        {tags.map((tag, index) => {
        if (!tag) return null;
        const tagLabel = typeof tag === "string" ? tag : tag.label;
        const tagVariant = typeof tag === "string" ? variant : tag.variant ?? variant;
        return <Tag key={`${tagLabel}-${index}`} label={tagLabel} variant={tagVariant} />;
      })}
      </div>
    </PrefixedBaseCell>;
};