import { gql } from "@apollo/client";

export const COLLECTION_FRAGMENTS = {
  id: gql`
    fragment CollectionId on Collection {
      id
    }
  `,
  name: gql`
    fragment CollectionName on Collection {
      name
    }
  `,
  collectionType: gql`
    fragment CollectionType on Collection {
      collectionType
    }
  `,
  createdAt: gql`
    fragment CollectionCreatedAt on Collection {
      createdAt
    }
  `,
  description: gql`
    fragment CollectionDescription on Collection {
      description
    }
  `,
  criteria: gql`
    fragment CollectionCriteria on Collection {
      criteria {
        filters {
          attribute
          operator
          value
        }
      }
    }
  `,
};
