import React from "react";
import { PrefixedBaseCell, BaseCellProps } from "@/edges/ui/BaseCell";
import { Avatar } from "@/edges/ui/Avatar";
import { Skeleton } from "@/edges/ui/Skeleton/Skeleton";
type CustomerCellProps = Omit<BaseCellProps, "children" | "loadingContent"> & {
  firstName: string;
  lastName: string;
  imageUrl?: string;
  isLoading?: boolean;
  isPrimaryCell?: boolean;
};
export const CustomerCell: React.FC<CustomerCellProps> = ({
  firstName,
  lastName,
  imageUrl,
  className,
  isPrimaryCell = false,
  isLoading,
  ...props
}) => {
  const content = <div className="flex max-w-full items-center gap-3">
      <div className="flex-shrink-0">
        <Avatar firstName={firstName} lastName={lastName} size="sm" imageUrl={imageUrl} variant="customer" />
      </div>
      <span className="relative top-[1px] max-w-full truncate">
        {`${firstName} ${lastName}`}
      </span>
    </div>;
  const loadingContent = <div className="flex max-w-full items-center gap-3">
      <div className="flex-shrink-0">
        <Skeleton width={32} height={32} />
      </div>
      <Skeleton width={160} height={16} />
    </div>;
  return <PrefixedBaseCell isPrimaryCell={isPrimaryCell} title={`${firstName} ${lastName}`} className={className} loadingContent={loadingContent} isLoading={isLoading} {...props} data-sentry-element="PrefixedBaseCell" data-sentry-component="CustomerCell" data-sentry-source-file="CustomerCell.tsx">
      {content}
    </PrefixedBaseCell>;
};