// @ts-nocheck

import {
  processGraphQLOperationErrorsAndThrow,
  processSingleOperationGraphQLResponse,
} from "@/utils";
import {
  CreateOrganizationInput,
  LoggedUserDocument,
  PreferredTemperatureUnit,
  UpdateOrganizationLocationMutationVariables,
  UpdateOrganizationMutationVariables,
  useCreateOrganizationMutation,
  useUpdateOrganizationLocationMutation,
  useUpdateOrganizationMutation as gqlUseUpdateOrganizationMutation,
  Organization,
  OrganizationMembersDocument,
  useDeleteMembershipMutation,
} from "@/@codegen/supergraph";

export function useOrganizationRepository() {
  const [updateOrganizationMutation] = gqlUseUpdateOrganizationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: LoggedUserDocument }],
  });

  const [createOrganizationMutation] = useCreateOrganizationMutation();
  const [deleteMembershipMutation] = useDeleteMembershipMutation();

  const [updateOrganizationLocationMutation] =
    useUpdateOrganizationLocationMutation({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: LoggedUserDocument }],
    });

  function updatePreferredTemperatureUnit({
    organization,
    unit,
  }: {
    organization: Organization;
    unit: PreferredTemperatureUnit;
  }) {
    return updateOrganizationMutation({
      variables: {
        id: organization.id,
        input: { preferredTemperatureUnit: unit },
      },
    })
      .then(processSingleOperationGraphQLResponse("updateOrganization"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function updateOrganization({ id, ...input }: UpdateOrganizationInput) {
    return updateOrganizationMutation({
      variables: { id, input },
    })
      .then(processSingleOperationGraphQLResponse("updateOrganization"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function updateOrganizationLocation({
    id,
    ...input
  }: UpdateOrganizationLocationInput) {
    return updateOrganizationLocationMutation({
      variables: {
        id,
        input: {
          // Hack because the backend expects all fields to be present. We should fix this on backend
          city: "",
          postalCode: "",
          state: "",
          streetOne: "",
          ...(input as UpdateOrganizationLocationMutationVariables["input"]),
        },
      },
    })
      .then(processSingleOperationGraphQLResponse("updateOrganizationLocation"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function createOrganization(input: CreateOrganizationInput) {
    return createOrganizationMutation({
      variables: { input },
    })
      .then(processSingleOperationGraphQLResponse("createOrganization"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function deleteMembership(id: string, organizationId?: string) {
    if (!organizationId) {
      throw new Error("Organization ID is required to delete a membership");
    }

    return deleteMembershipMutation({
      variables: {
        input: {
          filter: {
            organizationId,
            membershipId: id,
          },
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: OrganizationMembersDocument,
          variables: {
            input: {
              filter: { organizationId },
              pagination: {
                offset: {
                  perPage: 100,
                  page: 1,
                },
              },
            },
          },
        },
      ],
    })
      .then(processSingleOperationGraphQLResponse("deleteMembership"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  return {
    updatePreferredTemperatureUnit,
    updateOrganization,
    updateOrganizationLocation,
    createOrganization,
    deleteMembership,
  };
}

type UpdateOrganizationInput = Omit<
  UpdateOrganizationMutationVariables,
  "input"
> &
  UpdateOrganizationMutationVariables["input"];

export type UpdateOrganizationLocationInput = Omit<
  UpdateOrganizationLocationMutationVariables,
  "input"
> &
  Partial<UpdateOrganizationLocationMutationVariables["input"]>;
