import { FieldDefinition } from "@/types/fields";
import { TextCell } from "@/components/edges/ui/TextCell";
import { TimestampCell } from "@/components/edges/ui/TimestampCell";
export const LEAD_RESPONSE_PATH = ["leads", "data"];
export interface LeadRow {
  id: string;
  name: {
    firstName: string | null;
    lastName: string | null;
  };
  email: string | null;
  phone: string | null;
  address: {
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    postalCode: string | null;
  };
  timestamps: {
    createdAt: string | null;
  };
  // Fields that match the field definitions
  firstName: string | null;
  lastName: string | null;
  // Virtual fields
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  createdAt: string | null;
}
export const leadFields: FieldDefinition<LeadRow>[] = [{
  name: "id",
  label: "Lead ID",
  dataType: "String",
  entityType: "Lead",
  dataSources: [{
    type: "list",
    entityType: "Lead",
    fragmentKey: "id",
    responsePath: LEAD_RESPONSE_PATH
  }]
}, {
  name: "firstName",
  label: "First name",
  dataType: "String",
  entityType: "Lead",
  format: {
    type: "text",
    transform: "capitalize",
    nullText: ""
  },
  tableControl: {
    getValue: (row: LeadRow) => row.firstName ?? "",
    render: (row: LeadRow, {
      isLoading
    }) => <TextCell value={row.firstName ?? ""} isLoading={isLoading} />,
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Lead",
    fragmentKey: "name",
    responsePath: LEAD_RESPONSE_PATH
  }]
}, {
  name: "lastName",
  label: "Last name",
  dataType: "String",
  entityType: "Lead",
  format: {
    type: "text",
    transform: "capitalize",
    nullText: ""
  },
  tableControl: {
    getValue: (row: LeadRow) => row.lastName ?? "",
    render: (row: LeadRow, {
      isLoading
    }) => <TextCell value={row.lastName ?? ""} isLoading={isLoading} />,
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Lead",
    fragmentKey: "name",
    responsePath: LEAD_RESPONSE_PATH
  }]
}, {
  name: "email",
  label: "Email",
  dataType: "String",
  entityType: "Lead",
  format: {
    type: "text",
    transform: "lowercase",
    nullText: ""
  },
  display: {
    link: {
      type: "email",
      getHref: value => `mailto:${value}`
    }
  },
  tableControl: {
    getValue: (row: LeadRow) => row.email ?? "",
    render: (row: LeadRow, {
      isLoading
    }) => {
      const email = row.email;
      return <TextCell value={email ?? ""} href={`mailto:${email}`} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Lead",
    fragmentKey: "email",
    responsePath: LEAD_RESPONSE_PATH
  }]
}, {
  name: "phone",
  label: "Phone",
  dataType: "String",
  entityType: "Lead",
  format: {
    type: "phone",
    country: "US",
    format: "national",
    validate: true
  },
  display: {
    link: {
      type: "phone",
      getHref: value => `tel:${value}`
    }
  },
  tableControl: {
    getValue: (row: LeadRow) => row.phone ?? "",
    render: (row: LeadRow, {
      isLoading
    }) => {
      const phone = row.phone;
      return <TextCell value={phone ?? ""} href={`tel:${phone}`} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Lead",
    fragmentKey: "phone",
    responsePath: LEAD_RESPONSE_PATH
  }]
}, {
  name: "address1",
  label: "Street Address",
  dataType: "String",
  entityType: "Lead",
  format: {
    type: "text",
    transform: "capitalize",
    nullText: ""
  },
  tableControl: {
    getValue: (row: LeadRow) => row.address1 ?? "",
    render: (row: LeadRow, {
      isLoading
    }) => <TextCell value={row.address1 ?? ""} isLoading={isLoading} />,
    isSearchable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Lead",
    fragmentKey: "address",
    responsePath: LEAD_RESPONSE_PATH
  }]
}, {
  name: "address2",
  label: "Apt/Suite",
  dataType: "String",
  entityType: "Lead",
  format: {
    type: "text",
    transform: "capitalize",
    nullText: ""
  },
  tableControl: {
    getValue: (row: LeadRow) => row.address2 ?? "",
    render: (row: LeadRow, {
      isLoading
    }) => <TextCell value={row.address2 ?? ""} isLoading={isLoading} />,
    isSearchable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Lead",
    fragmentKey: "address",
    responsePath: LEAD_RESPONSE_PATH
  }]
}, {
  name: "city",
  label: "City",
  dataType: "String",
  entityType: "Lead",
  format: {
    type: "text",
    transform: "capitalize",
    nullText: ""
  },
  tableControl: {
    getValue: (row: LeadRow) => row.city ?? "",
    render: (row: LeadRow, {
      isLoading
    }) => <TextCell value={row.city ?? ""} isLoading={isLoading} />,
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Lead",
    fragmentKey: "address",
    responsePath: LEAD_RESPONSE_PATH
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct",
    getValue: (row: LeadRow) => row.city ?? ""
  }]
}, {
  name: "state",
  label: "State",
  dataType: "String",
  entityType: "Lead",
  format: {
    type: "text",
    transform: "uppercase",
    nullText: ""
  },
  tableControl: {
    getValue: (row: LeadRow) => row.state ?? "",
    render: (row: LeadRow, {
      isLoading
    }) => <TextCell value={row.state ?? ""} isLoading={isLoading} />,
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Lead",
    fragmentKey: "address",
    responsePath: LEAD_RESPONSE_PATH
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct",
    getValue: (row: LeadRow) => row.state ?? ""
  }]
}, {
  name: "postalCode",
  label: "Postal Code",
  dataType: "String",
  entityType: "Lead",
  format: {
    type: "text",
    nullText: ""
  },
  tableControl: {
    getValue: (row: LeadRow) => row.postalCode ?? "",
    render: (row: LeadRow, {
      isLoading
    }) => <TextCell value={row.postalCode ?? ""} isLoading={isLoading} />,
    isSearchable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Lead",
    fragmentKey: "address",
    responsePath: LEAD_RESPONSE_PATH
  }]
}, {
  name: "createdAt",
  label: "Created",
  dataType: "Date",
  entityType: "Lead",
  format: {
    type: "date",
    style: "medium",
    relative: true
  },
  tableControl: {
    getValue: (row: LeadRow) => row.createdAt ?? "",
    render: (row: LeadRow, {
      isLoading
    }) => <TimestampCell value={row.createdAt ?? ""} isLoading={isLoading} />,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Lead",
    fragmentKey: "timestamps",
    responsePath: LEAD_RESPONSE_PATH
  }]
}];