import { gql } from "@apollo/client";

/**
 * 🔹 Lead GraphQL Fragments
 * - These fragments define reusable field selections for leads.
 * - Used to ensure that only necessary data is fetched dynamically.
 */

export const LEAD_FRAGMENTS = {
  id: gql`
    fragment LeadId on Lead {
      id
    }
  `,
  name: gql`
    fragment LeadName on Lead {
      id
      firstName
      lastName
    }
  `,
  email: gql`
    fragment LeadEmail on Lead {
      id
      email
    }
  `,
  phone: gql`
    fragment LeadPhone on Lead {
      id
      phone
    }
  `,
  address: gql`
    fragment LeadAddress on Lead {
      id
      address1
      address2
      city
      state
      postalCode
    }
  `,
  timestamps: gql`
    fragment LeadTimestamps on Lead {
      id
      createdAt
    }
  `,
};
