import { useState } from "react";
import { WindowSize } from "@/@codegen/supergraph";
import { DateRanges, parseDateRange } from "@/utils/date";

export interface TimeControlsState {
  windowSize: WindowSize;
  setWindowSize: (size: WindowSize) => void;
  dateRange: {
    after: Date;
    before: Date;
  };
  setDateRange: (range: { after: Date; before: Date }) => void;
}

export function useTimeControls(): TimeControlsState {
  const [windowSize, setWindowSize] = useState<WindowSize>(WindowSize.Hour);
  const [dateRange, setDateRange] = useState(() =>
    parseDateRange(DateRanges.Last7Days),
  );

  return {
    windowSize,
    setWindowSize,
    dateRange,
    setDateRange,
  };
}
