import { gql } from "@apollo/client";

export const TIME_SERIES_FRAGMENTS = {
  TimeSeriesPoint: gql`
    fragment TimeSeriesPoint on TimeSeriesPoint {
      ts
      v
    }
  `,
  TimeSeriesData: gql`
    fragment TimeSeriesData on TimeSeriesData {
      data {
        ...TimeSeriesPoint
      }
    }
  `,
  TimeSeriesResponse: gql`
    fragment TimeSeriesResponse on TimeSeriesResponse {
      data {
        ...TimeSeriesPoint
      }
      pagination {
        total
      }
    }
  `,
  complete: gql`
    fragment TimeSeriesComplete on TimeSeriesResponse {
      data {
        ...TimeSeriesPoint
      }
      pagination {
        total
      }
    }
    ${gql`
      fragment TimeSeriesPoint on TimeSeriesPoint {
        ts
        v
      }
    `}
  `,
};
