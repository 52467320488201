import { FieldDefinition } from "@/types/fields";
import { Role } from "@/@codegen/supergraph/graphql";
import { TagCell } from "@/components/edges/ui/TagCell";
import { textTransforms } from "@/utils/formatting";
import InviteMenu from "@/app/(dashboard)/settings/organization/components/OrganizationMembersTable/components/InviteMenu";
import { useContext } from "react";
import { OrganizationContext } from "@/providers/organizations/OrganizationProvider";
import { useAuth } from "@/hooks/auth/useAuth";
import { useResendInvitationMutation, useRevokeInvitationMutation, useDeleteInvitationMutation } from "@/@codegen/supergraph";
import { toast } from "react-hot-toast";
export const ORGANIZATION_INVITATIONS_RESPONSE_PATH = ["organizationInvitations", "data"];
export interface OrganizationInvitationsFilter {
  organizationId: string;
}
export interface OrganizationInvitationsInput {
  filter: OrganizationInvitationsFilter;
  pagination?: {
    offset?: {
      page: number;
      perPage: number;
    };
  };
}
export interface InvitationRow extends Record<string, unknown> {
  id: string;
  email: string;
  role: Role;
  status: "ACCEPTED" | "PENDING" | "REVOKED";
  createdAt: string;
  updatedAt: string;
  organizationId: string;
  organizationName?: string;
  invitedByProfile?: {
    id: string;
    fullName?: string | null;
  } | null;
  userExists?: boolean;
  userId?: string;
}
export const organizationInvitationsFields: Array<FieldDefinition<InvitationRow>> = [{
  name: "id",
  label: "Invitation ID",
  dataType: "String",
  entityType: "OrganizationInvitation",
  format: {
    type: "text",
    transform: "lowercase"
  },
  tableControl: {
    getValue: row => row.id,
    isSearchable: true
  },
  dataSources: [{
    type: "list",
    entityType: "OrganizationInvitation",
    fragmentKey: "id",
    responsePath: ORGANIZATION_INVITATIONS_RESPONSE_PATH
  }]
}, {
  name: "email",
  label: "Email",
  dataType: "String",
  entityType: "OrganizationInvitation",
  format: {
    type: "text",
    transform: "lowercase"
  },
  display: {
    link: {
      type: "email",
      getHref: value => `mailto:${value as string}`
    }
  },
  tableControl: {
    getValue: row => row.email,
    isSearchable: true
  },
  dataSources: [{
    type: "list",
    entityType: "OrganizationInvitation",
    fragmentKey: "email",
    responsePath: ORGANIZATION_INVITATIONS_RESPONSE_PATH
  }]
}, {
  name: "roles",
  label: "Roles",
  dataType: "String",
  entityType: "OrganizationInvitation",
  format: {
    type: "text",
    transform: "titleCase"
  },
  tableControl: {
    getValue: row => {
      const roles = [row.role];
      return roles.join(",");
    },
    render: (row, {
      isLoading
    }) => {
      const roles = [row.role];
      return <TagCell tags={roles.map(role => textTransforms.titleCase(String(role)))} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "OrganizationInvitation",
    fragmentKey: "role",
    responsePath: ORGANIZATION_INVITATIONS_RESPONSE_PATH
  }]
}, {
  name: "status",
  label: "Status",
  dataType: "String",
  entityType: "OrganizationInvitation",
  format: {
    type: "text",
    transform: "titleCase"
  },
  tableControl: {
    getValue: row => row.status,
    render: (row, {
      isLoading
    }) => {
      const status = row.status ?? "PENDING";
      const variantMap: Record<string, "success" | "warning" | "error"> = {
        ACCEPTED: "success",
        PENDING: "warning",
        REVOKED: "error"
      };
      return <TagCell tags={[{
        label: textTransforms.titleCase(status),
        variant: variantMap[status]
      }]} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "OrganizationInvitation",
    fragmentKey: "status",
    responsePath: ORGANIZATION_INVITATIONS_RESPONSE_PATH
  }]
}, {
  name: "createdAt",
  label: "Created",
  dataType: "Date",
  entityType: "OrganizationInvitation",
  format: {
    type: "date",
    style: "medium",
    relative: true
  },
  tableControl: {
    getValue: row => row.createdAt,
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "OrganizationInvitation",
    fragmentKey: "timestamps",
    responsePath: ORGANIZATION_INVITATIONS_RESPONSE_PATH
  }]
}, {
  name: "updatedAt",
  label: "Updated",
  dataType: "Date",
  entityType: "OrganizationInvitation",
  format: {
    type: "date",
    style: "medium",
    relative: true
  },
  tableControl: {
    getValue: row => row.updatedAt,
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "OrganizationInvitation",
    fragmentKey: "timestamps",
    responsePath: ORGANIZATION_INVITATIONS_RESPONSE_PATH
  }]
}, {
  name: "actions",
  label: "Actions",
  dataType: "String",
  entityType: "OrganizationInvitation",
  tableControl: {
    getValue: () => "",
    render: (row, {
      isLoading
    }) => {
      if (isLoading) return null;
      const {
        currentOrganization
      } = useContext(OrganizationContext);
      const {
        user
      } = useAuth();
      const [resendInvitation] = useResendInvitationMutation();
      const [revokeInvitation] = useRevokeInvitationMutation();
      const [deleteInvitation] = useDeleteInvitationMutation();

      // Check if the user has an admin membership for this organization
      const isAdmin = user?.memberships?.some(membership => membership.organization?.id === currentOrganization?.id && membership.role === Role.Admin) || false;
      const handleResendInvitation = async (_email: string) => {
        return resendInvitation({
          variables: {
            input: {
              invitationId: row.id
            }
          }
        }).then(() => {
          toast.success("Invitation resent!");
          return true;
        }).catch(error => {
          console.error(error);
          toast.error("Failed to resend invitation");
          return false;
        });
      };
      const handleRevokeInvitation = async (id: string) => {
        return revokeInvitation({
          variables: {
            input: {
              id: id
            }
          }
        }).then(() => {
          toast.success("Invitation revoked!");
          return true;
        }).catch(error => {
          console.error(error);
          toast.error("Failed to revoke invitation");
          return false;
        });
      };
      const handleDeleteInvitation = async (id: string) => {
        return deleteInvitation({
          variables: {
            input: {
              id: id
            }
          }
        }).then(() => {
          toast.success("Invitation deleted!");
          return true;
        }).catch(error => {
          console.error(error);
          toast.error("Failed to delete invitation");
          return false;
        });
      };
      return <InviteMenu status={row.status} email={row.email} invitationId={row.id} resendInvitation={handleResendInvitation} revokeInvitation={handleRevokeInvitation} deleteInvitation={handleDeleteInvitation} isAdmin={isAdmin} />;
    },
    width: "80px"
  },
  dataSources: [{
    type: "list",
    entityType: "OrganizationInvitation",
    fragmentKey: "id",
    responsePath: ORGANIZATION_INVITATIONS_RESPONSE_PATH
  }]
}];