import React, { useCallback } from "react";
import { useTranslations } from "next-intl";
import { ActionMenu, ActionItem } from "@/edges/ui/ActionMenu/ActionMenu";
import { Button } from "@/edges/ui/Button";
import { Icon } from "@/edges/ui/Icon";
import { Modal } from "@/edges/ui/Modal";
import { AlertDialog } from "@/edges/ui/AlertDialog";
interface InviteMenuProps {
  isAdmin?: boolean;
  status?: string;
  email?: string;
  invitationId?: string;
  membershipId?: string;
  userName?: string;
  resendInvitation?: (email: string) => void;
  revokeInvitation?: (id: string) => void;
  deleteMembership?: (id: string) => void;
  deleteInvitation?: (id: string) => void;
}
const InviteMenu: React.FC<InviteMenuProps> = ({
  isAdmin,
  status,
  email,
  resendInvitation,
  invitationId,
  revokeInvitation,
  membershipId,
  deleteMembership,
  deleteInvitation
}) => {
  const t = useTranslations("invitation");
  const [isRevokeInvitationDialogOpen, setIsRevokeInvitationDialogOpen] = React.useState(false);
  const handleOpenRevokeInvitationDialog = useCallback(() => {
    setIsRevokeInvitationDialogOpen(true);
  }, []);
  const handleCloseRevokeInvitationDialog = () => {
    setIsRevokeInvitationDialogOpen(false);
  };
  const [isRemoveInvitationDialogOpen, setIsRemoveInvitationDialogOpen] = React.useState(false);
  const handleOpenRemoveInvitationDialog = useCallback(() => {
    setIsRemoveInvitationDialogOpen(true);
  }, []);
  const handleCloseRemoveInvitationDialog = () => {
    setIsRemoveInvitationDialogOpen(false);
  };
  const [isDeleteMemberDialogOpen, setIsDeleteMemberDialogOpen] = React.useState(false);
  const handleOpenDeleteMemberDialog = useCallback(() => {
    setIsDeleteMemberDialogOpen(true);
  }, []);
  const handleCloseDeleteMemberDialog = () => {
    setIsDeleteMemberDialogOpen(false);
  };
  const handleResendInvitation = useCallback(async () => {
    if (!email) return;
    return resendInvitation?.(email);
  }, [email, resendInvitation]);
  const handleRevokeInvitation = async () => {
    if (!invitationId) return;
    return revokeInvitation?.(invitationId);
  };
  const handleDeleteMembership = async () => {
    if (!membershipId) return;
    return deleteMembership?.(membershipId);
  };
  const handleDeleteInvitation = async () => {
    if (!invitationId) return;
    return deleteInvitation?.(invitationId);
  };

  // Define all hooks before conditional returns
  const items: ActionItem[] = React.useMemo(() => {
    if (status === "PENDING") {
      return [{
        id: 1,
        name: t("revoke"),
        iconName: "ArrowUp",
        onAction: handleOpenRevokeInvitationDialog
      }, {
        id: 2,
        name: t("resend"),
        iconName: "ArrowsClockwise",
        onAction: handleResendInvitation
      }, {
        id: 3,
        name: t("remove"),
        iconName: "Trash",
        variant: "destructive",
        onAction: handleOpenRemoveInvitationDialog
      }];
    }
    if (status === "ACCEPTED") {
      return [{
        id: 4,
        name: t("removeMember"),
        iconName: "Trash",
        variant: "destructive",
        onAction: handleOpenDeleteMemberDialog
      }];
    }
    if (status === "REVOKED") {
      return [{
        id: 5,
        name: t("remove"),
        iconName: "Trash",
        variant: "destructive",
        onAction: handleOpenRemoveInvitationDialog
      }];
    }
    return [];
  }, [status, t, handleOpenRevokeInvitationDialog, handleResendInvitation, handleOpenRemoveInvitationDialog, handleOpenDeleteMemberDialog]);

  // Move conditional return after all hooks have been defined
  if (!isAdmin) return " ";
  return <>
      <ActionMenu items={items} buttonComponent={<Button variant="ghost" size="sm">
            <Icon name="DotsThree" size={16} />
          </Button>} data-sentry-element="ActionMenu" data-sentry-source-file="index.tsx" />
      <Modal isDismissable isOpen={isRevokeInvitationDialogOpen} onOpenChange={handleCloseRevokeInvitationDialog} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
        <AlertDialog title={t("revoke")} actionLabel={t("confirm")} variant="destructive" onAction={handleRevokeInvitation} data-sentry-element="AlertDialog" data-sentry-source-file="index.tsx">
          {t("revokeConfirmationText")}
        </AlertDialog>
      </Modal>
      <Modal isDismissable isOpen={isRemoveInvitationDialogOpen} onOpenChange={handleCloseRemoveInvitationDialog} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
        <AlertDialog title={t("remove")} actionLabel={t("confirm")} variant="destructive" onAction={handleDeleteInvitation} data-sentry-element="AlertDialog" data-sentry-source-file="index.tsx">
          {t("removeInvitationConfirmationText")}
        </AlertDialog>
      </Modal>
      <Modal isDismissable isOpen={isDeleteMemberDialogOpen} onOpenChange={handleCloseDeleteMemberDialog} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
        <AlertDialog title={t("removeMember")} actionLabel={t("confirm")} variant="destructive" onAction={handleDeleteMembership} data-sentry-element="AlertDialog" data-sentry-source-file="index.tsx">
          {t("removeMemberConfirmationText")}
        </AlertDialog>
      </Modal>
    </>;
};
export default InviteMenu;