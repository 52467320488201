import type { EntityType } from "./types";

export type QueryPattern = {
  // The actual operation name in the schema (e.g. "customer" vs "customerById")
  operationName: string;
  // How the variables should be structured
  variablePattern: {
    // The variable name and type (e.g. "id: ID!" or "input: CustomerInput!")
    type: string;
    // How to transform the variables into the expected shape
    transform: (
      variables: Record<string, unknown>,
    ) => Record<string, unknown> | string;
  };
  // How the response is structured
  responsePattern: {
    // Path to the actual data (e.g. ["data"] or [])
    dataPath: string[];
    // Whether this query includes metadata like totalCount
    includesMeta?: boolean;
  };
};

export type QueryPatternType = "list" | "single" | "timeSeries" | "commands";

export const QUERY_PATTERNS: Record<
  EntityType,
  {
    list: QueryPattern;
    single: QueryPattern;
    timeSeries?: QueryPattern;
    commands?: QueryPattern;
  }
> = {
  customer: {
    list: {
      operationName: "customersForWorkspace",
      variablePattern: {
        type: "CustomersForWorkspaceInput!",
        transform: (vars) => ({ input: vars }),
      },
      responsePattern: {
        dataPath: ["data"],
        includesMeta: true,
      },
    },
    single: {
      operationName: "customer",
      variablePattern: {
        type: "ID!",
        transform: (vars) => vars.id as string,
      },
      responsePattern: {
        dataPath: [],
        includesMeta: false,
      },
    },
  },
  site: {
    list: {
      operationName: "sites",
      variablePattern: {
        type: "SitesInput!",
        transform: (vars) => ({ input: vars }),
      },
      responsePattern: {
        dataPath: ["data"],
        includesMeta: true,
      },
    },
    single: {
      operationName: "site",
      variablePattern: {
        type: "ID!",
        transform: (vars) => vars.id as string,
      },
      responsePattern: {
        dataPath: [],
        includesMeta: false,
      },
    },
    timeSeries: {
      operationName: "energyConsumption",
      variablePattern: {
        type: "MetricQueryInput!",
        transform: (vars) => ({ input: vars }),
      },
      responsePattern: {
        dataPath: ["data"],
        includesMeta: true,
      },
    },
  },
  lead: {
    list: {
      operationName: "leads",
      variablePattern: {
        type: "LeadsForWorkspaceInput!",
        transform: (vars) => ({ input: vars }),
      },
      responsePattern: {
        dataPath: ["data"],
        includesMeta: true,
      },
    },
    single: {
      operationName: "leadById",
      variablePattern: {
        type: "LeadByIdInput!",
        transform: (vars) => ({ input: vars }),
      },
      responsePattern: {
        dataPath: ["data"],
        includesMeta: false,
      },
    },
  },
  device: {
    list: {
      operationName: "devices",
      variablePattern: {
        type: "DevicesInput!",
        transform: (vars) => ({ input: vars }),
      },
      responsePattern: {
        dataPath: ["data"],
        includesMeta: true,
      },
    },
    single: {
      operationName: "device",
      variablePattern: {
        type: "ID!",
        transform: (vars) => vars.id as string,
      },
      responsePattern: {
        dataPath: [],
        includesMeta: false,
      },
    },
    commands: {
      operationName: "deviceCommands",
      variablePattern: {
        type: "DeviceCommandsInput!",
        transform: (vars) => ({ input: vars }),
      },
      responsePattern: {
        dataPath: ["data"],
        includesMeta: true,
      },
    },
  },
  organizationMembership: {
    list: {
      operationName: "organizationMembers",
      variablePattern: {
        type: "OrganizationMembersInput!",
        transform: (vars) => ({ input: vars }),
      },
      responsePattern: {
        dataPath: ["data"],
        includesMeta: true,
      },
    },
    single: {
      operationName: "organizationMemberById",
      variablePattern: {
        type: "OrganizationMemberByIdInput!",
        transform: (vars) => ({ input: vars }),
      },
      responsePattern: {
        dataPath: ["data"],
        includesMeta: false,
      },
    },
  },
  organizationInvitation: {
    list: {
      operationName: "organizationInvitations",
      variablePattern: {
        type: "OrganizationInvitationsInput!",
        transform: (vars) => ({ input: vars }),
      },
      responsePattern: {
        dataPath: ["data"],
        includesMeta: true,
      },
    },
    single: {
      operationName: "organizationInvitationById",
      variablePattern: {
        type: "OrganizationInvitationByIdInput!",
        transform: (vars) => ({ input: vars }),
      },
      responsePattern: {
        dataPath: ["data"],
        includesMeta: false,
      },
    },
  },
  collection: {
    list: {
      operationName: "getCollectionsForWorkspace",
      variablePattern: {
        type: "GetCollectionsForWorkspaceInput!",
        transform: (vars) => ({ input: vars }),
      },
      responsePattern: {
        dataPath: ["data"],
        includesMeta: true,
      },
    },
    single: {
      operationName: "collectionById",
      variablePattern: {
        type: "CollectionByIdInput!",
        transform: (vars) => ({ input: vars }),
      },
      responsePattern: {
        dataPath: ["data"],
        includesMeta: false,
      },
    },
  },
} as const;
