import { FieldDefinition } from "@/types/fields";
import { DeviceMetaCell } from "@/components/edges/ui/DeviceMetaCell";
import { DeviceStateCell } from "@/components/edges/ui/DeviceStateCell";
import { TagCell } from "@/components/edges/ui/TagCell";
import { TimestampCell } from "@/components/edges/ui/TimestampCell";
import { TextCell } from "@/components/edges/ui/TextCell";
import { DeviceState } from "@/components/edges/types/DeviceStates";
import { GridState } from "@/components/edges/types/GridStates";
import { NumberFormat, NumberNotation } from "@/types/fields/formats/NumberFormat";
import { DateRange } from "@/utils/date";
import { WindowSize, CommandStatus } from "@/@codegen/supergraph";
import { EnergyUnit } from "@/types/fields/formats/EnergyFormat";
import { EnergyFormat } from "@/types/fields/formats/EnergyFormat";
import type { BatteryState, ChargerState, InverterState, ThermostatState, VehicleState } from "@/@codegen/supergraph/graphql";
import { STATE_FIELD_MAPPINGS } from "./fragments/deviceFragments";
import { createTimeSeriesDataSource, createDeviceStateHistoryDataSource, DEFAULT_TIME_SERIES_QUERY_PARAMS, DEFAULT_DEVICE_STATE_HISTORY_QUERY_PARAMS } from "./dataSources";
import { BaseDataSource } from "@/types/fields";

// Command type from GraphQL schema
interface CommandType {
  description: string;
  inputSchema: unknown | null;
  slug: string;
}

// Command from GraphQL schema
interface Command {
  createdAt: string;
  id: string;
  input: unknown | null;
  reason: string | null;
  status: CommandStatus;
  type: CommandType;
  updatedAt: string | null;
}

// Device command history query params
export interface DeviceCommandHistoryQueryParams {
  input: {
    filter: {
      id: string;
      workspaceId: string;
      status?: CommandStatus[];
    };
  };
}

// Device command history data source type
interface DeviceCommandHistoryDataSource<T = unknown> {
  type: "list";
  entityType: string;
  fragmentKey: keyof T;
  responsePath: string[];
  queryParams: DeviceCommandHistoryQueryParams;
}
const createDeviceCommandHistoryDataSource = (options?: {
  queryParams?: Partial<DeviceCommandHistoryQueryParams>;
}): DeviceCommandHistoryDataSource<DeviceRow> => ({
  type: "list",
  entityType: "query",
  fragmentKey: "commandHistory" as keyof DeviceRow,
  responsePath: ["deviceCommands", "data"],
  queryParams: {
    input: {
      filter: {
        id: "${id}",
        workspaceId: "${workspaceId}",
        ...options?.queryParams?.input?.filter
      }
    }
  }
});
export const DEVICE_RESPONSE_PATH = ["devices", "data"];

// Default to last 24 hours
const DEFAULT_DATE_RANGE: DateRange = {
  after: new Date(Date.now() - 24 * 60 * 60 * 1000),
  before: new Date()
};

// Regular time series query params (for metrics)
export interface TimeSeriesQueryParams {
  filter: {
    siteId?: string;
    deviceId?: string;
    workspaceId?: string;
    type?: string;
    range?: DateRange;
  };
  window: {
    size: WindowSize;
  };
}
export const DEFAULT_DEVICE_QUERY_PARAMS: TimeSeriesQueryParams = {
  filter: {
    range: DEFAULT_DATE_RANGE
  },
  window: {
    size: WindowSize.Hour
  }
};

// Device state history query params
export interface DeviceStateHistoryQueryParams {
  filter: {
    id: string;
    from: string;
    to?: string;
  };
  pagination?: {
    offset: {
      perPage: number;
    };
  };
}

// Combined query params type
export type QueryParams = TimeSeriesQueryParams | DeviceStateHistoryQueryParams;

// Update TimeSeriesDataSource type to use the combined query params
export interface TimeSeriesDataSource<T> {
  type: "timeSeries";
  entityType: string;
  responsePath: string[];
  timeField: string;
  valueField: string;
  fragmentKey: keyof T;
  queryParams: T extends DeviceRow ? DeviceStateHistoryQueryParams | TimeSeriesQueryParams : TimeSeriesQueryParams;
  isDeviceStateHistory?: boolean;
  transform?: (data: unknown) => unknown;
}

// Type for time series data point
interface TimeSeriesDataPoint {
  ts: string;
  v: number;
  deviceId?: string | null;
  siteId?: string | null;
  customerId?: string | null;
  tags?: string[] | null;
}

// Type for time series metadata
interface TimeSeriesMetadata {
  label: string;
  series?: string[] | null;
  sparse: boolean;
  unit: string;
  displayUnit: string;
  domainMeta: {
    label: string;
    sparse: boolean;
    rate: {
      unit: string;
      displayUnit: string;
      scaleFactor: number;
    };
  };
}

// Type for time series response
interface TimeSeriesResponse {
  data: TimeSeriesDataPoint[];
  meta: TimeSeriesMetadata;
}

// Type for device state history data point
type DeviceStateHistoryDataPoint = {
  createdAt: string;
} & ((BatteryState & {
  __typename: "BatteryState";
}) | (ChargerState & {
  __typename: "ChargerState";
}) | (InverterState & {
  __typename: "InverterState";
}) | (ThermostatState & {
  __typename: "ThermostatState";
}) | (VehicleState & {
  __typename: "VehicleState";
}));
export interface DeviceRow {
  id: string;
  modelProfile: {
    name: string;
  };
  manufacturerProfile: {
    name: string;
    icon: string | null;
  };
  type: string;
  state: (BatteryState & {
    __typename: "BatteryState";
  }) | (ChargerState & {
    __typename: "ChargerState";
  }) | (InverterState & {
    __typename: "InverterState";
  }) | (ThermostatState & {
    __typename: "ThermostatState";
  }) | (VehicleState & {
    __typename: "VehicleState";
  });
  gridStatus?: string;
  tags?: string[];
  connectedAt: string | null;
  updatedAt: string | null;
  // Virtual fields
  meta: {
    modelProfile: {
      name: string;
    };
    manufacturerProfile: {
      name: string;
      icon: string | null;
    };
  };
  // Fragment keys for time series data
  deviceStateHistory: DeviceStateHistoryDataPoint[];
  energyEmissions: TimeSeriesDataPoint[];
  energyConsumption: TimeSeriesDataPoint[];
  energyProduction: TimeSeriesDataPoint[];
  energyStorage: TimeSeriesDataPoint[];
  // Fragment key for timestamps
  timestamps: {
    connectedAt: string | null;
    updatedAt: string | null;
  };
  // Command history
  commandHistory: Command[];
}

// Device command history data source type
interface CommandHistoryDataSource<T = unknown> extends BaseDataSource<T> {
  queryParams: Record<string, unknown>;
}
export const deviceFields: FieldDefinition<DeviceRow>[] = [{
  name: "id",
  label: "Id",
  dataType: "String",
  entityType: "Device",
  format: {
    type: "text",
    transform: "lowercase"
  },
  display: {
    link: {
      type: "internal",
      getHref: (value: unknown, row: DeviceRow) => `/devices/${row.id}`
    }
  },
  tableControl: {
    width: "100px",
    getValue: (row: DeviceRow) => row.id,
    render: (row: DeviceRow, {
      isLoading
    }) => <TextCell value={row.id} href={`/devices/${row.id}`} format="truncateStart" isPrimaryCell isLoading={isLoading} />,
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "id",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "id",
    responsePath: ["device"]
  }]
}, {
  name: "type",
  label: "Type",
  dataType: "String",
  entityType: "Device",
  format: {
    type: "text",
    transform: "sentenceCase"
  },
  tableControl: {
    getValue: (row: DeviceRow) => row.type,
    render: (row: DeviceRow, {
      isLoading
    }) => <TextCell value={row.type} isLoading={isLoading} />,
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "type",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "type",
    responsePath: ["device"]
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct"
  }]
}, {
  name: "manufacturer",
  label: "Manufacturer",
  dataType: "String",
  entityType: "Device",
  tableControl: {
    getValue: (row: DeviceRow) => row.manufacturerProfile?.name ?? "",
    render: (row: DeviceRow, {
      isLoading
    }) => <TextCell value={row.manufacturerProfile?.name ?? ""} isLoading={isLoading} />,
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "meta",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "meta",
    responsePath: ["device"]
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct",
    getValue: (row: DeviceRow) => row.manufacturerProfile?.name ?? ""
  }]
}, {
  name: "model",
  label: "Model",
  dataType: "String",
  entityType: "Device",
  tableControl: {
    getValue: (row: DeviceRow) => row.modelProfile?.name ?? "",
    render: (row: DeviceRow, {
      isLoading
    }) => <TextCell value={row.modelProfile?.name ?? ""} isLoading={isLoading} />,
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "meta",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "meta",
    responsePath: ["device"]
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct",
    getValue: (row: DeviceRow) => row.modelProfile?.name ?? ""
  }]
}, {
  name: "device",
  label: "Device",
  dataType: "String",
  entityType: "Device",
  tableControl: {
    getValue: (row: DeviceRow) => {
      return `${row.manufacturerProfile?.name || ""} ${row.modelProfile?.name || ""}`;
    },
    render: (row: DeviceRow, {
      isLoading
    }) => {
      return <DeviceMetaCell manufacturer={row.manufacturerProfile?.name} model={row.modelProfile?.name} logoUrl={row.manufacturerProfile?.icon} deviceType={row.type?.toLowerCase() as "battery" | "charger" | "inverter" | "thermostat" | "vehicle"} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "meta",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "meta",
    responsePath: ["device"]
  }]
}, {
  name: "state",
  label: "State",
  dataType: "String",
  entityType: "Device",
  tableControl: {
    getValue: (row: DeviceRow) => {
      const stateType = row.state?.__typename;
      if (stateType === "BatteryState" || stateType === "VehicleState" || stateType === "ChargerState") {
        return row.state.chargingState?.toLowerCase() ?? "unknown";
      } else if (stateType === "ThermostatState") {
        return row.state.operatingMode?.toLowerCase() ?? "unknown";
      } else if (stateType === "InverterState") {
        const power = row.state.power ?? undefined;
        return power && power > 0 ? "on" : "idle";
      }
      return "unknown";
    },
    render: (row: DeviceRow, {
      isLoading
    }) => {
      const stateType = row.state?.__typename;
      let stateValue: DeviceState | undefined;
      let metric;
      let metricFormat: "percentage" | "temperature" | "amps" | "kW" | undefined;
      let gridStatus: GridState | undefined;
      if (isLoading) {
        stateValue = "unknown";
      } else if (stateType === "BatteryState") {
        stateValue = (row.state.chargingState?.toLowerCase() ?? "unknown") as DeviceState;
        metric = row.state.chargePercentage ? Math.round(row.state.chargePercentage) : undefined;
        metricFormat = "percentage";
        gridStatus = row.state.gridStatus?.toLowerCase() as GridState;
      } else if (stateType === "ThermostatState") {
        stateValue = (row.state.operatingMode?.toLowerCase() ?? "unknown") as DeviceState;
        metric = row.state.coolTarget ?? undefined;
        metricFormat = "temperature";
      } else if (stateType === "InverterState") {
        const power = row.state.power ?? undefined;
        stateValue = power && power > 0 ? "on" : "idle";
        metric = power === 0 ? 0 : power && power > 1 && Number.isInteger(power) ? Math.round(power) : power;
        metricFormat = "kW";
      } else if (stateType === "VehicleState") {
        stateValue = (row.state.chargingState?.toLowerCase() ?? "unknown") as DeviceState;
        metric = row.state.chargePercentage ?? undefined;
        metricFormat = "percentage";
      } else if (stateType === "ChargerState") {
        stateValue = (row.state.chargingState?.toLowerCase() ?? "unknown") as DeviceState;
        metric = row.state.chargerCurrent ?? undefined;
        metricFormat = "amps";
      } else {
        stateValue = "unknown";
      }
      return <DeviceStateCell state={stateValue} gridStatus={gridStatus} metric={metric} metricFormat={metricFormat} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: ["device"]
  }]
}, {
  name: "chargingState",
  label: "Charging state",
  dataType: "String",
  entityType: "Device",
  format: {
    type: "text",
    transform: "sentenceCase"
  },
  tableControl: {
    getValue: (row: DeviceRow) => {
      const stateType = row.state?.__typename;
      if (stateType === "BatteryState" || stateType === "VehicleState" || stateType === "ChargerState") {
        return row.state.chargingState?.toLowerCase() ?? "";
      }
      return "";
    },
    render: (row: DeviceRow, {
      isLoading
    }) => {
      const stateType = row.state?.__typename;
      const value = stateType === "BatteryState" || stateType === "VehicleState" || stateType === "ChargerState" ? row.state.chargingState?.toLowerCase() : undefined;
      return <TextCell value={value} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: ["device"]
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct"
  }]
}, {
  name: "operatingMode",
  label: "Operating mode",
  dataType: "String",
  entityType: "Device",
  format: {
    type: "text",
    transform: "sentenceCase"
  },
  tableControl: {
    getValue: (row: DeviceRow) => {
      const stateType = row.state?.__typename;
      if (stateType === "ThermostatState") {
        return row.state.operatingMode?.toLowerCase() ?? "";
      }
      return "";
    },
    render: (row: DeviceRow, {
      isLoading
    }) => {
      const stateType = row.state?.__typename;
      const value = stateType === "ThermostatState" ? row.state.operatingMode?.toLowerCase() : undefined;
      return <TextCell value={value} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: ["device"]
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct"
  }]
}, {
  name: "fanMode",
  label: "Fan mode",
  dataType: "String",
  entityType: "Device",
  format: {
    type: "text",
    transform: "sentenceCase"
  },
  tableControl: {
    getValue: (row: DeviceRow) => {
      const stateType = row.state?.__typename;
      if (stateType === "ThermostatState") {
        return row.state.fanMode?.toLowerCase() ?? "";
      }
      return "";
    },
    render: (row: DeviceRow, {
      isLoading
    }) => {
      const stateType = row.state?.__typename;
      const value = stateType === "ThermostatState" ? row.state.fanMode?.toLowerCase() : undefined;
      return <TextCell value={value} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: ["device"]
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct"
  }]
}, {
  name: "chargePercentage",
  label: "Charge percentage",
  dataType: "Number",
  entityType: "device",
  dataSources: [createDeviceStateHistoryDataSource("chargePercentage")],
  format: {
    type: "number",
    notation: "standard" as NumberNotation,
    precision: {
      exact: 2
    },
    unit: "%",
    showUnit: true
  } as NumberFormat
}, {
  name: "deviceGridStatus",
  label: "Grid status",
  dataType: "String",
  entityType: "Device",
  tableControl: {
    getValue: (row: DeviceRow) => {
      const stateType = row.state?.__typename;
      if (stateType === "BatteryState") {
        return row.state.gridStatus?.toLowerCase() ?? "";
      }
      return "";
    },
    render: (row: DeviceRow, {
      isLoading
    }) => {
      const stateType = row.state?.__typename;
      const value = stateType === "BatteryState" ? row.state.gridStatus?.toLowerCase() : undefined;
      return <TextCell value={value} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: ["device"]
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct"
  }]
}, {
  name: "chargerCurrent",
  label: "Charger current",
  dataType: "Number",
  entityType: "device",
  dataSources: [createDeviceStateHistoryDataSource("chargerCurrent")],
  format: {
    type: "number",
    notation: "standard" as NumberNotation,
    precision: {
      exact: 2
    },
    unit: "A",
    showUnit: true
  } as NumberFormat
}, {
  name: "powerOutput",
  label: "Power output",
  dataType: "Number",
  entityType: "device",
  dataSources: [createDeviceStateHistoryDataSource("powerOutput")],
  format: {
    type: "number",
    notation: "standard" as NumberNotation,
    precision: {
      exact: 2
    },
    unit: "kW",
    showUnit: true
  } as NumberFormat
}, {
  name: "coolTarget",
  label: "Cool target",
  dataType: "Number",
  entityType: "Device",
  format: {
    type: "number",
    notation: "standard" as NumberNotation,
    precision: {
      exact: 1
    },
    unit: "°",
    showUnit: true
  } as NumberFormat,
  tableControl: {
    getValue: (row: DeviceRow) => {
      const stateType = row.state?.__typename;
      if (stateType === "ThermostatState") {
        return row.state.coolTarget ?? 0;
      }
      return 0;
    },
    render: (row: DeviceRow, {
      isLoading
    }) => {
      const stateType = row.state?.__typename;
      const value = stateType === "ThermostatState" ? row.state.coolTarget !== undefined ? `${row.state.coolTarget}°` : undefined : undefined;
      return <TextCell value={value} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: ["device"]
  }, createDeviceStateHistoryDataSource("coolTarget", {
    queryParams: {
      filter: {
        id: "${id}",
        from: DEFAULT_DATE_RANGE.after.toISOString(),
        to: DEFAULT_DATE_RANGE.before.toISOString()
      },
      pagination: {
        offset: {
          perPage: 1000
        }
      }
    }
  })],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct"
  }]
}, {
  name: "heatTarget",
  label: "Heat target",
  dataType: "Number",
  entityType: "Device",
  format: {
    type: "number",
    notation: "standard" as NumberNotation,
    precision: {
      exact: 1
    },
    unit: "°",
    showUnit: true
  } as NumberFormat,
  tableControl: {
    getValue: (row: DeviceRow) => {
      const stateType = row.state?.__typename;
      if (stateType === "ThermostatState") {
        return row.state.heatTarget ?? 0;
      }
      return 0;
    },
    render: (row: DeviceRow, {
      isLoading
    }) => {
      const stateType = row.state?.__typename;
      const value = stateType === "ThermostatState" ? row.state.heatTarget !== undefined ? `${row.state.heatTarget}°` : undefined : undefined;
      return <TextCell value={value} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: ["device"]
  }, createDeviceStateHistoryDataSource("heatTarget", {
    queryParams: {
      filter: {
        id: "${id}",
        from: DEFAULT_DATE_RANGE.after.toISOString(),
        to: DEFAULT_DATE_RANGE.before.toISOString()
      },
      pagination: {
        offset: {
          perPage: 1000
        }
      }
    }
  })],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct"
  }]
}, {
  name: "gridStatus",
  label: "Grid status",
  dataType: "String",
  entityType: "Device",
  tableControl: {
    getValue: (row: DeviceRow) => {
      const stateType = row.state?.__typename;
      if (stateType === "InverterState" || stateType === "BatteryState") {
        return row.state.gridStatus?.toLowerCase() ?? "";
      }
      return row.gridStatus ?? "";
    },
    render: (row: DeviceRow, {
      isLoading
    }) => {
      const stateType = row.state?.__typename;
      const value = stateType === "InverterState" || stateType === "BatteryState" ? row.state.gridStatus?.toLowerCase() : row.gridStatus;
      return <TextCell value={value ?? ""} isLoading={isLoading} />;
    },
    isSearchable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: ["device"]
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct"
  }]
}, {
  name: "tags",
  label: "Tags",
  dataType: "String",
  entityType: "Device",
  tableControl: {
    getValue: (row: DeviceRow) => (row.tags ?? []).join(", "),
    render: (row: DeviceRow, {
      isLoading
    }) => <TagCell tags={row.tags} isLoading={isLoading} />,
    isSearchable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "tags",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "tags",
    responsePath: ["device"]
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct"
  }]
}, {
  name: "connectedAt",
  label: "Connected",
  dataType: "Date",
  entityType: "Device",
  format: {
    type: "date",
    style: "medium",
    relative: true
  },
  tableControl: {
    getValue: (row: DeviceRow) => row.connectedAt ?? "",
    render: (row: DeviceRow, {
      isLoading
    }) => <TimestampCell value={row.connectedAt} isLoading={isLoading} />,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "timestamps",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "timestamps",
    responsePath: ["device"]
  }]
}, {
  name: "updatedAt",
  label: "Updated",
  dataType: "Date",
  entityType: "Device",
  format: {
    type: "date",
    style: "medium",
    relative: true
  },
  tableControl: {
    getValue: (row: DeviceRow) => row.updatedAt ?? "",
    render: (row: DeviceRow, {
      isLoading
    }) => <TimestampCell value={row.updatedAt ?? ""} isLoading={isLoading} />,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "timestamps",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "timestamps",
    responsePath: ["device"]
  }]
}, {
  name: "energyEmissions",
  label: "Marginal emissions",
  dataType: "Number",
  entityType: "device",
  dataSources: [createTimeSeriesDataSource("ts", "v", {
    queryParams: {
      filter: {
        range: DEFAULT_DATE_RANGE,
        deviceId: "${id}"
      },
      window: {
        size: WindowSize.Hour
      }
    }
  })],
  format: {
    type: "number",
    notation: "standard" as NumberNotation,
    precision: {
      exact: 2
    },
    unit: "kgCO2"
  } as NumberFormat
}, {
  name: "energyConsumption",
  label: "Energy consumption",
  dataType: "Number",
  entityType: "device",
  dataSources: [createTimeSeriesDataSource("ts", "v", {
    queryParams: {
      filter: {
        range: DEFAULT_DATE_RANGE,
        deviceId: "${id}"
      },
      window: {
        size: WindowSize.Hour
      }
    }
  })],
  format: {
    type: "energy",
    preferredUnit: "kWh" as EnergyUnit,
    notation: "standard" as NumberNotation,
    precision: 2,
    showUnit: true
  } as EnergyFormat
}, {
  name: "energyProduction",
  label: "Energy production",
  dataType: "Number",
  entityType: "device",
  dataSources: [createTimeSeriesDataSource("ts", "v", {
    queryParams: {
      filter: {
        range: DEFAULT_DATE_RANGE,
        deviceId: "${id}"
      },
      window: {
        size: WindowSize.Hour
      }
    }
  })],
  format: {
    type: "energy",
    preferredUnit: "kWh" as EnergyUnit,
    notation: "standard" as NumberNotation,
    precision: 2,
    showUnit: true
  } as EnergyFormat
}, {
  name: "charge",
  label: "Charge",
  dataType: "Number",
  entityType: "Device",
  format: {
    type: "energy",
    preferredUnit: "kWh" as EnergyUnit,
    notation: "standard" as NumberNotation,
    precision: 2,
    showUnit: true
  } as EnergyFormat,
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: ["device"]
  }, createDeviceStateHistoryDataSource("charge", {
    queryParams: {
      filter: {
        id: "${id}",
        from: DEFAULT_DATE_RANGE.after.toISOString(),
        to: DEFAULT_DATE_RANGE.before.toISOString()
      },
      pagination: {
        offset: {
          perPage: 1000
        }
      }
    }
  })]
}, {
  name: "chargeRate",
  label: "Charge rate",
  dataType: "Number",
  entityType: "Device",
  format: {
    type: "energy",
    preferredUnit: "kW" as EnergyUnit,
    notation: "standard" as NumberNotation,
    precision: 2,
    showUnit: true
  } as EnergyFormat,
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: ["device"]
  }, createDeviceStateHistoryDataSource("chargeRate", {
    queryParams: {
      filter: {
        id: "${id}",
        from: DEFAULT_DATE_RANGE.after.toISOString(),
        to: DEFAULT_DATE_RANGE.before.toISOString()
      },
      pagination: {
        offset: {
          perPage: 1000
        }
      }
    }
  })]
}, {
  name: "gridPower",
  label: "Grid power",
  dataType: "Number",
  entityType: "Device",
  format: {
    type: "energy",
    preferredUnit: "kW" as EnergyUnit,
    notation: "standard" as NumberNotation,
    precision: 2,
    showUnit: true
  } as EnergyFormat,
  tableControl: {
    getValue: (row: DeviceRow) => {
      const stateType = row.state?.__typename;
      if (stateType === "InverterState" || stateType === "BatteryState") {
        return row.state.gridPower ?? 0;
      }
      return 0;
    },
    render: (row: DeviceRow, {
      isLoading
    }) => {
      const stateType = row.state?.__typename;
      let value;
      if (stateType === "InverterState" || stateType === "BatteryState") {
        const gridPower = row.state.gridPower;
        value = gridPower !== undefined ? `${gridPower} kW` : undefined;
      }
      return <TextCell value={value} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: ["device"]
  }, createDeviceStateHistoryDataSource("gridPower", {
    queryParams: {
      filter: {
        id: "${id}",
        from: DEFAULT_DATE_RANGE.after.toISOString(),
        to: DEFAULT_DATE_RANGE.before.toISOString()
      },
      pagination: {
        offset: {
          perPage: 1000
        }
      }
    }
  })]
}, {
  name: "gridEnergy",
  label: "Grid energy",
  dataType: "Number",
  entityType: "Device",
  format: {
    type: "energy",
    preferredUnit: "kWh" as EnergyUnit,
    notation: "standard" as NumberNotation,
    precision: 2,
    showUnit: true
  } as EnergyFormat,
  tableControl: {
    getValue: (row: DeviceRow) => {
      const stateType = row.state?.__typename;
      if (stateType === "InverterState" || stateType === "BatteryState") {
        return row.state.gridEnergy ?? 0;
      }
      return 0;
    },
    render: (row: DeviceRow, {
      isLoading
    }) => {
      const stateType = row.state?.__typename;
      let value;
      if (stateType === "InverterState" || stateType === "BatteryState") {
        const gridEnergy = row.state.gridEnergy;
        value = gridEnergy !== undefined ? `${gridEnergy} kWh` : undefined;
      }
      return <TextCell value={value} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: ["device"]
  }, createDeviceStateHistoryDataSource("gridEnergy", {
    queryParams: {
      filter: {
        id: "${id}",
        from: DEFAULT_DATE_RANGE.after.toISOString(),
        to: DEFAULT_DATE_RANGE.before.toISOString()
      },
      pagination: {
        offset: {
          perPage: 1000
        }
      }
    }
  })]
}, {
  name: "backupReserve",
  label: "Backup reserve",
  dataType: "Number",
  entityType: "Device",
  format: {
    type: "number",
    notation: "standard" as NumberNotation,
    precision: {
      exact: 0
    },
    unit: "%",
    showUnit: true
  } as NumberFormat,
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: ["device"]
  }, createDeviceStateHistoryDataSource("backupReserve", {
    queryParams: {
      filter: {
        id: "${id}",
        from: DEFAULT_DATE_RANGE.after.toISOString(),
        to: DEFAULT_DATE_RANGE.before.toISOString()
      },
      pagination: {
        offset: {
          perPage: 1000
        }
      }
    }
  })]
}, {
  name: "ambientTemperature",
  label: "Ambient temperature",
  dataType: "Number",
  entityType: "Device",
  format: {
    type: "number",
    notation: "standard" as NumberNotation,
    precision: {
      exact: 1
    },
    unit: "°",
    showUnit: true
  } as NumberFormat,
  tableControl: {
    getValue: (row: DeviceRow) => {
      const stateType = row.state?.__typename;
      if (stateType === "ThermostatState") {
        return row.state.ambientTemperature ?? 0;
      }
      return 0;
    },
    render: (row: DeviceRow, {
      isLoading
    }) => {
      const stateType = row.state?.__typename;
      const value = stateType === "ThermostatState" ? row.state.ambientTemperature !== undefined ? `${row.state.ambientTemperature}°` : undefined : undefined;
      return <TextCell value={value} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: DEVICE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Device",
    fragmentKey: "state",
    responsePath: ["device"]
  }, createDeviceStateHistoryDataSource("ambientTemperature", {
    queryParams: {
      filter: {
        id: "${id}",
        from: DEFAULT_DATE_RANGE.after.toISOString(),
        to: DEFAULT_DATE_RANGE.before.toISOString()
      },
      pagination: {
        offset: {
          perPage: 1000
        }
      }
    }
  })]
}, {
  name: "charging",
  label: "Charging",
  dataType: "Number",
  entityType: "device",
  dataSources: [createDeviceStateHistoryDataSource("charging", {
    isDeviceStateHistory: true
  })],
  format: {
    type: "number",
    notation: "standard" as NumberNotation,
    precision: {
      exact: 2
    },
    unit: "kW"
  } as NumberFormat
}, {
  name: "power",
  label: "Power",
  dataType: "Number",
  entityType: "device",
  dataSources: [createDeviceStateHistoryDataSource("power", {
    entityType: "device",
    fragmentKey: "deviceStateHistory",
    queryParams: {
      filter: {
        id: "${id}",
        from: DEFAULT_DATE_RANGE.after.toISOString(),
        to: DEFAULT_DATE_RANGE.before.toISOString()
      },
      pagination: {
        offset: {
          perPage: 1000
        }
      }
    }
  })],
  format: {
    type: "energy",
    preferredUnit: "kW",
    notation: "standard",
    precision: 2,
    showUnit: true
  } as EnergyFormat
}, {
  name: "chargerVoltage",
  label: "Charger voltage",
  dataType: "Number",
  entityType: "device",
  dataSources: [createDeviceStateHistoryDataSource("chargerVoltage", {
    entityType: "device",
    fragmentKey: "deviceStateHistory",
    queryParams: {
      filter: {
        id: "${id}",
        from: DEFAULT_DATE_RANGE.after.toISOString(),
        to: DEFAULT_DATE_RANGE.before.toISOString()
      },
      pagination: {
        offset: {
          perPage: 1000
        }
      }
    }
  })],
  format: {
    type: "number",
    notation: "standard",
    precision: {
      exact: 1
    },
    unit: "V",
    showUnit: true
  } as NumberFormat
}, {
  name: "chargerWattage",
  label: "Charger wattage",
  dataType: "Number",
  entityType: "device",
  dataSources: [createDeviceStateHistoryDataSource("chargerWattage", {
    entityType: "device",
    fragmentKey: "deviceStateHistory",
    queryParams: {
      filter: {
        id: "${id}",
        from: DEFAULT_DATE_RANGE.after.toISOString(),
        to: DEFAULT_DATE_RANGE.before.toISOString()
      },
      pagination: {
        offset: {
          perPage: 1000
        }
      }
    }
  })],
  format: {
    type: "energy",
    preferredUnit: "kW",
    showUnit: true,
    notation: "standard",
    precision: 2
  } as EnergyFormat
}, {
  name: "range",
  label: "Range",
  dataType: "Number",
  entityType: "device",
  dataSources: [createDeviceStateHistoryDataSource("range", {
    entityType: "device",
    fragmentKey: "deviceStateHistory",
    queryParams: {
      filter: {
        id: "${id}",
        from: DEFAULT_DATE_RANGE.after.toISOString(),
        to: DEFAULT_DATE_RANGE.before.toISOString()
      },
      pagination: {
        offset: {
          perPage: 1000
        }
      }
    }
  })],
  format: {
    type: "number",
    notation: "standard",
    precision: {
      exact: 1
    },
    unit: "mi",
    showUnit: true
  } as NumberFormat
}, {
  name: "commandHistory",
  label: "Command history",
  dataType: "String",
  entityType: "device",
  dataSources: [{
    type: "commands",
    entityType: "device",
    fragmentKey: "commandHistory",
    responsePath: ["deviceCommands"],
    queryParams: {
      input: {
        filter: {
          id: "${id}",
          workspaceId: "${workspaceId}"
        }
      }
    }
  }]
}];