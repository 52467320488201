import React from "react";
import type { DeviceState as DeviceStateType } from "../../types/DeviceStates";
import type { GridState } from "../../types/GridStates";
import { Skeleton } from "@/edges/ui/Skeleton";
export interface DeviceStateProps {
  state: DeviceStateType | GridState;
  className?: string;
  isLoading?: boolean;
}
const getStateLabel = (state: DeviceStateType | GridState): string => {
  return state.charAt(0).toUpperCase() + state.slice(1);
};
interface StateClasses {
  background: string;
  text: string;
}
const stateClasses: Record<DeviceStateType | GridState, StateClasses> = {
  unknown: {
    background: "bg-device-unknown-background",
    text: "text-device-unknown-text"
  },
  on: {
    background: "bg-device-on-background",
    text: "text-device-on-text"
  },
  off: {
    background: "bg-device-off-background",
    text: "text-device-off-text"
  },
  idle: {
    background: "bg-device-idle-background",
    text: "text-device-idle-text"
  },
  charging: {
    background: "bg-device-charging-background",
    text: "text-device-charging-text"
  },
  discharging: {
    background: "bg-device-discharging-background",
    text: "text-device-discharging-text"
  },
  heat: {
    background: "bg-device-heat-background",
    text: "text-device-heat-text"
  },
  eco: {
    background: "bg-device-eco-background",
    text: "text-device-eco-text"
  },
  cool: {
    background: "bg-device-cool-background",
    text: "text-device-cool-text"
  },
  auto: {
    background: "bg-device-auto-background",
    text: "text-device-auto-text"
  },
  importing: {
    background: "bg-device-importing-background",
    text: "text-device-importing-text"
  },
  exporting: {
    background: "bg-device-exporting-background",
    text: "text-device-exporting-text"
  }
};
export const DeviceState: React.FC<DeviceStateProps> = ({
  state,
  className,
  isLoading
}): React.ReactNode => {
  const label = getStateLabel(state);
  const classes = stateClasses[state];
  if (isLoading) {
    return <Skeleton className={`inline-flex h-6 w-28 items-center justify-center rounded-full px-2 ${classes.background} ${classes.text} ${className ?? ""}`} />;
  }
  return <div className={`inline-flex h-6 w-28 items-center justify-center rounded-full px-2 ${classes.background} ${classes.text} ${className ?? ""}`} data-sentry-component="DeviceState" data-sentry-source-file="DeviceState.tsx">
      <span>{label}</span>
    </div>;
};