import { gql } from "@apollo/client";
import { LOCATION_FRAGMENTS } from "./locationFragments";
import type {
  BatteryState,
  ChargerState,
  InverterState,
  ThermostatState,
  VehicleState,
} from "@/@codegen/supergraph/graphql";

/**
 * 🔹 Device GraphQL Fragments
 * - These fragments define reusable field selections for devices.
 * - Used to ensure that only necessary data is fetched dynamically.
 */

// Define state field mappings - single source of truth for what fields are available in each state
export const STATE_FIELD_MAPPINGS = {
  BatteryState: {
    chargingState: "String",
    chargePercentage: "Float",
    gridStatus: "String",
    charge: "Float",
    chargeRate: "Float",
    gridPower: "Float",
    gridEnergy: "Float",
    backupReserve: "Float",
  },
  ChargerState: {
    chargingState: "String",
    chargerCurrent: "Float",
    chargerVoltage: "Float",
    chargerWattage: "Float",
  },
  InverterState: {
    power: "Float",
    gridStatus: "String",
    gridPower: "Float",
    gridEnergy: "Float",
  },
  ThermostatState: {
    operatingMode: "String",
    coolTarget: "Float",
    heatTarget: "Float",
    ambientTemperature: "Float",
    fanMode: "String",
  },
  VehicleState: {
    chargingState: "String",
    chargerCurrent: "Float",
    chargerVoltage: "Float",
    chargerWattage: "Float",
    chargePercentage: "Float",
    charge: "Float",
    range: "Float",
  },
} as const;

type DeviceStateType = keyof typeof STATE_FIELD_MAPPINGS;
type DeviceState =
  | BatteryState
  | ChargerState
  | InverterState
  | ThermostatState
  | VehicleState;

// Helper to create a fragment for a specific field in a state type
const createStateFieldFragment = (typeName: DeviceStateType, field: string) => {
  if (!(field in STATE_FIELD_MAPPINGS[typeName])) {
    return null;
  }
  return `... on ${typeName} {
    ${field}
  }`;
};

// Helper to create a fragment for a specific field across all relevant state types
export const createDeviceStateFieldFragment = (field: string) => {
  const fragments = Object.keys(STATE_FIELD_MAPPINGS)
    .map((typeName) =>
      createStateFieldFragment(typeName as DeviceStateType, field),
    )
    .filter(Boolean);

  return fragments.length > 0
    ? gql`
      fragment DeviceStateField_${field} on DeviceState {
        ${fragments.join("\n")}
      }
    `
    : null;
};

// Base fragments that don't change often
export const DEVICE_FRAGMENTS = {
  id: gql`
    fragment DeviceIdFields on Device {
      id
    }
  `,
  meta: gql`
    fragment DeviceMeta on Device {
      id
      modelProfile {
        name
      }
      manufacturerProfile {
        name
        icon
      }
      type
    }
  `,
  state: gql`
    fragment DeviceState on Device {
      id
      state {
        __typename
        ${Object.keys(STATE_FIELD_MAPPINGS)
          .map(
            (typeName) => `... on ${typeName} {
            ${Object.keys(STATE_FIELD_MAPPINGS[typeName as DeviceStateType]).join("\n            ")}
          }`,
          )
          .join("\n        ")}
      }
    }
  `,
  tags: gql`
    fragment DeviceTags on Device {
      id
      tags
    }
  `,
  timestamps: gql`
    fragment DeviceTimestamps on Device {
      id
      connectedAt
      disconnectedAt
      updatedAt
    }
  `,
  site: gql`
    fragment DeviceSiteFields on Device {
      site {
        id
        location {
          ...LocationFields
        }
      }
    }
    ${LOCATION_FRAGMENTS.location}
  `,
  commandHistory: gql`
    fragment DeviceCommandHistory on DeviceCommandPaginatedQueryPayload {
      data {
        createdAt
        id
        input
        reason
        status
        type {
          description
          inputSchema
          slug
        }
        updatedAt
      }
      error
    }
  `,
} as const;

// Device state history fragments - now field-specific
export const DEVICE_STATE_HISTORY_FRAGMENTS = {
  base: gql`
    fragment DeviceStateHistoryBase on DeviceStateHistoryResponse {
      data {
        createdAt
        __typename
      }
    }
  `,
  // Helper to create a field-specific state history fragment
  createFieldFragment: (field: string) => {
    const fragment = createDeviceStateFieldFragment(field);
    if (!fragment) return null;

    return gql`
      fragment DeviceStateHistory_${field} on DeviceStateHistoryResponse {
        data {
          createdAt
          __typename
          state {
            ${fragment}
          }
        }
      }
      ${fragment}
    `;
  },
};

// Time series fragments for metric data
export const TIME_SERIES_FRAGMENTS = {
  base: gql`
    fragment TimeSeriesBase on MetricPayloadResponse {
      data {
        ts
        v
      }
    }
  `,
  complete: gql`
    fragment TimeSeriesComplete on MetricPayloadResponse {
      data {
        ts
        v
        deviceId
        siteId
        customerId
        tags
      }
      meta {
        label
        series
        sparse
        unit
        displayUnit
        domainMeta {
          label
          sparse
          rate {
            unit
            displayUnit
            scaleFactor
          }
        }
      }
    }
  `,
};
