import { gql } from "@apollo/client";

export const LOCATION_FRAGMENTS = {
  location: gql`
    fragment LocationFields on Location {
      id
      streetOne
      streetTwo
      city
      state
      country
      postalCode
      fullAddress
      hashedAddress
      latitude
      longitude
      createdAt
      updatedAt
    }
  `,
};
