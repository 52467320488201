"use client";

import { isNil } from "lodash";

import {
  CreateOrganizationInput as RepositoryCreateOrganizationInput,
  Organization,
} from "@/@codegen/supergraph/graphql";

import { useAuthenticationService } from "@/services/authenticationService";
import useLoggedUserFunctions from "@/hooks/useLoggedUserFunctions";

import {
  UpdateOrganizationLocationInput,
  useOrganizationRepository,
} from "@/repositories/organizationRepository";

function isOrganization(value: unknown): value is Organization {
  return (
    typeof value === "object" &&
    value !== null &&
    "id" in value &&
    typeof value.id === "string"
  );
}

export function useOrganizationService() {
  const authenticationService = useAuthenticationService();
  const organizationRepository = useOrganizationRepository();

  const { isOrganizationInLoggedUserMemberships } = useLoggedUserFunctions();

  async function switchOrganizationSession(organizationId: string) {
    if (isOrganizationInLoggedUserMemberships(organizationId) === false) {
      throw new Error(
        "Cannot switch to organization because organization is not in logged user memberships.",
      );
    }

    return authenticationService.refreshSession(organizationId);
  }

  async function createOrganization(input: CreateOrganizationInput) {
    if (isNil(input.domain)) {
      input.domain = input.name;
    }

    const { country, ...createOrganizationInput } = input;

    const newOrganization = await organizationRepository.createOrganization(
      createOrganizationInput as RepositoryCreateOrganizationInput,
    );

    if (!isOrganization(newOrganization)) {
      throw new Error("Invalid organization data received from repository");
    }

    if (country) {
      await organizationRepository.updateOrganizationLocation({
        id: newOrganization.id,
        country,
      });
    }

    return newOrganization;
  }

  async function deleteMembership(id: string, organizationId?: string) {
    return organizationRepository.deleteMembership(id, organizationId);
  }

  return { switchOrganizationSession, createOrganization, deleteMembership };
}

export type CreateOrganizationInput = Omit<
  RepositoryCreateOrganizationInput,
  "domain"
> &
  Pick<UpdateOrganizationLocationInput, "country"> &
  Partial<Pick<RepositoryCreateOrganizationInput, "domain">>;
