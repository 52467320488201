import React from "react";
import { PrefixedBaseCell, BaseCellProps } from "@/edges/ui/BaseCell";
import Image from "next/image";
import { Skeleton } from "@/edges/ui/Skeleton/Skeleton";
import { Icon } from "@/components/edges/ui/Icon";
interface DeviceMetaCellProps extends Omit<BaseCellProps, "children" | "loadingContent"> {
  manufacturer: string;
  model: string;
  logoUrl: string | null;
  deviceType: "battery" | "charger" | "inverter" | "thermostat" | "vehicle";
}
export const DeviceMetaCell: React.FC<DeviceMetaCellProps> = ({
  manufacturer,
  model,
  logoUrl,
  deviceType,
  ...props
}) => {
  const iconName = deviceType === "battery" ? "BatteryHigh" : deviceType === "charger" ? "ChargingStation" : deviceType === "inverter" ? "Power" : deviceType === "thermostat" ? "ThermometerSimple" : deviceType === "vehicle" ? "CarSimple" : "Device";
  const content = <div className="flex items-center gap-3">
      <div className="flex-shrink-0">
        {logoUrl && <Image src={logoUrl} alt={`${manufacturer} logo`} width={36} height={36} className="object-contain" />}
      </div>
      <div className="flex h-full min-w-0 max-w-[300px] flex-col justify-center">
        <div className="text-textPrimary dark:text-textPrimaryDark flex h-5 items-center truncate font-semibold">
          {manufacturer}
        </div>
        <div className="flex h-5 min-w-0 items-center gap-1.5 text-[#717171] dark:text-textMutedDark">
          <Icon name={iconName} size={14} className="text-text-secondary flex-shrink-0" />
          <span className="truncate" title={model}>
            {model}
          </span>
        </div>
      </div>
    </div>;
  const loadingContent = <div className="flex items-center gap-3">
      <div className="flex-shrink-0">
        <Skeleton width={36} height={36} />
      </div>
      <div className="flex h-full flex-col justify-center">
        <div className="flex h-5 items-center">
          <Skeleton width={160} height={16} />
        </div>
        <div className="flex h-5 items-center">
          <Skeleton width={160} height={16} />
        </div>
      </div>
    </div>;
  return <PrefixedBaseCell {...props} loadingContent={loadingContent} data-sentry-element="PrefixedBaseCell" data-sentry-component="DeviceMetaCell" data-sentry-source-file="DeviceMetaCell.tsx">
      {content}
    </PrefixedBaseCell>;
};