import { type FieldDefinition } from "@/types/fields";
import { TextCell } from "@/components/edges/ui/TextCell";
// Define the response path for collection queries
const COLLECTION_RESPONSE_PATH = ["getCollectionsForWorkspace", "data"];

// Interface for Collection row data
export interface CollectionRow extends Record<string, unknown> {
  id: string;
  name: string;
  collectionType: string;
  description?: string;
  dynamic: boolean;
  itemCount?: number;
  createdById: string;
  createdAt: string;
  updatedAt: string;
  visibility?: string;
}
export const collectionFields: Array<FieldDefinition<CollectionRow>> = [{
  name: "id",
  label: "ID",
  dataType: "String",
  entityType: "collection",
  tableControl: {
    width: "100px",
    getValue: row => String(row.id || ""),
    render: (row, {
      isLoading
    }) => <TextCell value={row.id} href={`/collections/${row.id}`} format="truncateStart" isPrimaryCell isLoading={isLoading} />,
    isSearchable: false
  },
  dataSources: [{
    type: "list",
    entityType: "collection",
    fragmentKey: "id",
    responsePath: COLLECTION_RESPONSE_PATH
  }]
}, {
  name: "name",
  label: "Collection Name",
  dataType: "String",
  entityType: "collection",
  format: {
    type: "text",
    transform: "capitalize",
    nullText: ""
  },
  tableControl: {
    width: "200px",
    getValue: row => String(row.name || ""),
    render: (row, {
      isLoading
    }) => <TextCell value={row.name} isLoading={isLoading} />,
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "collection",
    fragmentKey: "name",
    responsePath: COLLECTION_RESPONSE_PATH
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct"
  }]
}, {
  name: "collectionType",
  label: "Type",
  dataType: "String",
  entityType: "collection",
  format: {
    type: "text",
    transform: "capitalize",
    nullText: ""
  },
  tableControl: {
    getValue: row => String(row.collectionType || ""),
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "collection",
    fragmentKey: "collectionType",
    responsePath: COLLECTION_RESPONSE_PATH
  }]
}, {
  name: "createdAt",
  label: "Created Date",
  dataType: "String",
  entityType: "collection",
  format: {
    type: "date",
    style: "medium",
    relative: true
  },
  tableControl: {
    getValue: row => String(row.createdAt || ""),
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "collection",
    fragmentKey: "createdAt",
    responsePath: COLLECTION_RESPONSE_PATH
  }]
}, {
  name: "description",
  label: "Description",
  dataType: "String",
  entityType: "collection",
  format: {
    type: "text",
    nullText: ""
  },
  tableControl: {
    getValue: row => String(row.description || "")
  },
  dataSources: [{
    type: "list",
    entityType: "collection",
    fragmentKey: "description",
    responsePath: COLLECTION_RESPONSE_PATH
  }]
}];