import React, { ReactNode } from "react";
import { chain } from "react-aria";
import { DialogProps } from "react-aria-components";
import { Button } from "@/edges/ui/Button";
import { Dialog } from "@/edges/ui/Dialog";
import { Heading } from "@/edges/ui/Heading";
import { Icon } from "@/edges/ui/Icon";
import { TranslationValues, useTranslations } from "next-intl";
interface AlertDialogProps extends Omit<DialogProps, "children"> {
  title: string;
  titleContext?: TranslationValues;
  children: ReactNode;
  variant?: "info" | "destructive";
  actionLabel: string;
  cancelLabel?: string;
  onAction?: () => void;
}
export function AlertDialog({
  title,
  titleContext,
  variant,
  cancelLabel,
  actionLabel,
  onAction,
  children,
  ...props
}: AlertDialogProps) {
  const t = useTranslations();
  return <Dialog {...props} className="h-auto" data-sentry-element="Dialog" data-sentry-component="AlertDialog" data-sentry-source-file="AlertDialog.tsx">
      {({
      close
    }) => <>
          <div className="flex h-15 items-center justify-between border-b pl-6 pr-2">
            <Heading size="xs">{t(title, titleContext)}</Heading>
            <Button variant="icon" onPress={close}>
              <Icon name="X" />
            </Button>
          </div>
          <div className="max-h-[calc(95vh-140px)] overflow-y-auto px-6 py-8 md:max-h-[calc(70vh-140px)]">
            {children}
          </div>
          <div className="flex h-20 items-center justify-end gap-2 border-t px-6">
            <Button variant="secondary" size="lg" onPress={close} data-testid="alert-dialog-cancel">
              {cancelLabel || t("Cancel")}
            </Button>
            <Button variant={variant === "destructive" ? "destructive" : "primary"} size="lg" data-testid="alert-dialog-confirm" onPress={chain(onAction, close)}>
              {actionLabel || t("Confirm")}
            </Button>
          </div>
        </>}
    </Dialog>;
}