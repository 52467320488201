import type { FieldDefinition } from "@/types/fields";
import type { TimeSeriesDataSource } from "@/types/fields/dataSources/TimeSeriesDataSource";
import type { WindowSize } from "@/@codegen/supergraph";
import { MetricsQueryGroupByFacet } from "@/@codegen/supergraph";
import type { NumberFormat, NumberNotation } from "@/types/fields/formats/NumberFormat";
import type { EnergyFormat, EnergyUnit } from "@/types/fields/formats/EnergyFormat";
import { SiteCell } from "@/components/edges/ui/SiteCell";
import { CustomerCell } from "@/components/edges/ui/CustomerCell";
import { TimestampCell } from "@/components/edges/ui/TimestampCell";
import { DateRange } from "@/utils/date";
export const SITE_RESPONSE_PATH = ["sites", "data"];
export interface SiteRow {
  id: string;
  location: {
    streetOne: string | null;
    streetTwo: string | null;
    city: string | null;
    state: string | null;
    postalCode: string | null;
    latitude?: number;
    longitude?: number;
  };
  connectedCustomers: Array<{
    id: string;
    firstName: string | null;
    lastName: string | null;
  }>;
  timestamps: {
    createdAt: string | null;
    updatedAt: string | null;
  };
  createdAt: string | null;
  updatedAt: string | null;
  // Virtual fields
  customer: {
    id: string;
    firstName: string | null;
    lastName: string | null;
  } | null;
  city: string | null;
  state: string | null;
  latitude: number | null;
  longitude: number | null;
  energyConsumption?: {
    timestamp: string;
    value: number;
    unit: "kWh";
  }[];
  energyProduction?: {
    timestamp: string;
    value: number;
    unit: "kWh";
  }[];
  energyStorage?: {
    timestamp: string;
    value: number;
    unit: "kWh";
  }[];
  gridEnergy?: {
    timestamp: string;
    value: number;
    unit: "kWh";
  }[];
  energyEmissions?: {
    timestamp: string;
    value: number;
    unit: "kgCO2";
  }[];
}

// Default to last 24 hours
const DEFAULT_DATE_RANGE: DateRange = {
  after: new Date(Date.now() - 24 * 60 * 60 * 1000),
  before: new Date()
};
export const DEFAULT_SITE_QUERY_PARAMS = {
  filter: {
    range: DEFAULT_DATE_RANGE
  }
};
const createTimeSeriesDataSource = (timeField: string, valueField: string, options: Partial<TimeSeriesDataSource<SiteRow>> = {}): TimeSeriesDataSource<SiteRow> => ({
  type: "timeSeries",
  entityType: "site",
  fragmentKey: "energyConsumption" as keyof SiteRow,
  responsePath: ["data", "metrics", "timeSeries"],
  timeField,
  valueField,
  queryParams: DEFAULT_SITE_QUERY_PARAMS,
  ...options
});
export const siteFields: FieldDefinition<SiteRow>[] = [{
  name: "id",
  label: "Site ID",
  dataType: "String",
  entityType: "Site",
  dataSources: [{
    type: "list",
    entityType: "Site",
    fragmentKey: "id",
    responsePath: SITE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Site",
    fragmentKey: "id",
    responsePath: ["site"]
  }]
}, {
  name: "location",
  label: "Address",
  dataType: "String",
  entityType: "Site",
  format: {
    type: "text",
    transform: "capitalize",
    nullText: ""
  },
  tableControl: {
    getValue: (row: SiteRow) => {
      return `${row.location?.streetOne ?? ""}, ${row.location?.city ?? ""}, ${row.location?.state ?? ""}`;
    },
    render: (row: SiteRow, {
      isLoading
    }) => {
      return <SiteCell isPrimaryCell streetOne={row.location?.streetOne ?? ""} streetTwo={row.location?.streetTwo ?? ""} city={row.location?.city ?? ""} state={row.location?.state ?? ""} postalCode={row.location?.postalCode ?? ""} href={`/sites/${row.id}`} isLoading={isLoading} />;
    },
    isSearchable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Site",
    fragmentKey: "location",
    responsePath: SITE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Site",
    fragmentKey: "location",
    responsePath: ["site"]
  }]
}, {
  name: "customer",
  label: "Customer",
  dataType: "String",
  entityType: "Site",
  format: {
    type: "text",
    transform: "capitalize",
    nullText: ""
  },
  tableControl: {
    getValue: (row: SiteRow) => {
      const customer = row.connectedCustomers?.[0];
      return customer ? `${customer.firstName ?? ""} ${customer.lastName ?? ""}`.trim() : "";
    },
    render: (row: SiteRow, {
      isLoading
    }) => {
      const customer = row.connectedCustomers?.[0];
      if (!customer && !isLoading) return null;
      return <CustomerCell firstName={customer?.firstName ?? ""} lastName={customer?.lastName ?? ""} href={customer ? `/customers/${customer.id}` : undefined} isPrimaryCell={false} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Site",
    fragmentKey: "customer",
    responsePath: SITE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Site",
    fragmentKey: "customer",
    responsePath: ["site"]
  }]
}, {
  name: "createdAt",
  label: "Created",
  dataType: "Date",
  entityType: "Site",
  format: {
    type: "text",
    transform: "capitalize"
  },
  tableControl: {
    getValue: (row: SiteRow) => row.createdAt ?? "",
    render: (row: SiteRow, {
      isLoading
    }) => <TimestampCell value={row.createdAt ?? ""} isLoading={isLoading} />,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Site",
    fragmentKey: "timestamps",
    responsePath: SITE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Site",
    fragmentKey: "timestamps",
    responsePath: ["site"]
  }]
}, {
  name: "updatedAt",
  label: "Updated",
  dataType: "Date",
  entityType: "Site",
  format: {
    type: "text",
    transform: "capitalize"
  },
  tableControl: {
    getValue: (row: SiteRow) => row.updatedAt ?? "",
    render: (row: SiteRow, {
      isLoading
    }) => <TimestampCell value={row.updatedAt ?? ""} isLoading={isLoading} />,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Site",
    fragmentKey: "timestamps",
    responsePath: SITE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Site",
    fragmentKey: "timestamps",
    responsePath: ["site"]
  }]
}, {
  name: "city",
  label: "City",
  dataType: "String",
  entityType: "Site",
  format: {
    type: "text",
    transform: "capitalize",
    nullText: ""
  },
  tableControl: {
    getValue: (row: SiteRow) => row.location?.city ?? "",
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Site",
    fragmentKey: "location",
    responsePath: SITE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Site",
    fragmentKey: "location",
    responsePath: ["site"]
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct",
    getValue: (row: SiteRow) => row.location?.city ?? ""
  }]
}, {
  name: "state",
  label: "State",
  dataType: "String",
  entityType: "Site",
  format: {
    type: "text",
    transform: "uppercase",
    nullText: ""
  },
  tableControl: {
    getValue: (row: SiteRow) => row.location?.state ?? "",
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Site",
    fragmentKey: "location",
    responsePath: SITE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Site",
    fragmentKey: "location",
    responsePath: ["site"]
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct",
    getValue: (row: SiteRow) => row.location?.state ?? ""
  }]
}, {
  name: "latitude",
  label: "Latitude",
  dataType: "Number",
  entityType: "Site",
  tableControl: {
    getValue: (row: SiteRow) => row.location?.latitude ?? "",
    isSearchable: false,
    isSortable: false
  },
  dataSources: [{
    type: "list",
    entityType: "Site",
    fragmentKey: "location",
    responsePath: SITE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Site",
    fragmentKey: "location",
    responsePath: ["site"]
  }]
}, {
  name: "longitude",
  label: "Longitude",
  dataType: "Number",
  entityType: "Site",
  tableControl: {
    getValue: (row: SiteRow) => row.location?.longitude ?? "",
    isSearchable: false,
    isSortable: false
  },
  dataSources: [{
    type: "list",
    entityType: "Site",
    fragmentKey: "location",
    responsePath: SITE_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Site",
    fragmentKey: "location",
    responsePath: ["site"]
  }]
},
// Time series fields
{
  name: "energyConsumption",
  label: "Energy Consumption",
  dataType: "Number",
  entityType: "site",
  dataSources: [createTimeSeriesDataSource("timestamp", "value", {
    fragmentKey: "energyConsumption",
    queryParams: {
      ...DEFAULT_SITE_QUERY_PARAMS,
      filter: {
        type: "consumption",
        range: DEFAULT_DATE_RANGE
      }
    }
  })],
  format: {
    type: "energy",
    preferredUnit: "kWh" as EnergyUnit,
    notation: "standard" as NumberNotation,
    precision: 2
  } as EnergyFormat
}, {
  name: "energyProduction",
  label: "Energy Production",
  dataType: "Number",
  entityType: "site",
  dataSources: [createTimeSeriesDataSource("timestamp", "value", {
    fragmentKey: "energyProduction",
    queryParams: {
      ...DEFAULT_SITE_QUERY_PARAMS,
      filter: {
        type: "production",
        range: DEFAULT_DATE_RANGE
      }
    }
  })],
  format: {
    type: "energy",
    preferredUnit: "kWh" as EnergyUnit,
    notation: "standard" as NumberNotation,
    precision: 2
  } as EnergyFormat
}, {
  name: "energyStorage",
  label: "Energy Storage",
  dataType: "Number",
  entityType: "site",
  dataSources: [createTimeSeriesDataSource("timestamp", "value", {
    fragmentKey: "energyStorage",
    queryParams: {
      ...DEFAULT_SITE_QUERY_PARAMS,
      filter: {
        type: "storage",
        range: DEFAULT_DATE_RANGE
      }
    }
  })],
  format: {
    type: "energy",
    preferredUnit: "kWh" as EnergyUnit,
    notation: "standard" as NumberNotation,
    precision: 2
  } as EnergyFormat
}, {
  name: "gridEnergy",
  label: "Grid Energy",
  dataType: "Number",
  entityType: "site",
  dataSources: [createTimeSeriesDataSource("timestamp", "value", {
    fragmentKey: "gridEnergy",
    queryParams: {
      ...DEFAULT_SITE_QUERY_PARAMS,
      filter: {
        type: "grid",
        range: DEFAULT_DATE_RANGE
      }
    }
  })],
  format: {
    type: "energy",
    preferredUnit: "kWh" as EnergyUnit,
    notation: "standard" as NumberNotation,
    precision: 2
  } as EnergyFormat
}, {
  name: "energyEmissions",
  label: "Marginal Emissions",
  dataType: "Number",
  entityType: "site",
  dataSources: [createTimeSeriesDataSource("timestamp", "value", {
    fragmentKey: "energyEmissions",
    queryParams: {
      ...DEFAULT_SITE_QUERY_PARAMS,
      filter: {
        type: "emissions",
        range: DEFAULT_DATE_RANGE
      }
    }
  })],
  format: {
    type: "number",
    notation: "standard" as NumberNotation,
    precision: {
      exact: 2
    }
  } as NumberFormat
}];