import { useQuery } from "@apollo/client";
import { buildEntityQuery } from "@/config/fields/buildEntityQuery";
import { ENTITY_FIELDS } from "@/config/fields";
import { useWorkspace } from "@/hooks/organization/useWorkspace";
import type { EntityType, FieldName } from "@/config/fields/types";
import { QUERY_PATTERNS } from "@/config/fields/queryPatterns";

// Input variables structure for single entity
export interface EntitySingleDataVariables {
  id: string;
  workspaceId?: string;
}

// Query response structure for single entity
export interface EntitySingleResponse<T> {
  data: T;
}

interface QueryResponse<T extends EntityType> {
  [key: string]: EntitySingleResponse<unknown>;
}

interface UseEntitySingleDataProps<T extends EntityType> {
  entityType: T;
  selectedFields: FieldName<T>[];
  id: string;
}

/**
 * Hook for fetching a single entity by ID.
 */
export function useEntitySingleData<T extends EntityType>({
  entityType,
  selectedFields,
  id,
}: UseEntitySingleDataProps<T>) {
  const { currentWorkspace } = useWorkspace();
  const query = buildEntityQuery(entityType, selectedFields, "single");
  const pattern = QUERY_PATTERNS[entityType].single;

  // Get the response path from the first single data source we find
  const responsePath = ENTITY_FIELDS[entityType]
    .find((field) =>
      (field.dataSources ?? []).some((ds) => ds.type === "single"),
    )
    ?.dataSources?.find((ds) => ds.type === "single")?.responsePath;

  if (!responsePath) {
    throw new Error(
      `No single data source found for entity type: ${entityType}`,
    );
  }

  // Build variables using the pattern's transform function
  const baseVars: Record<string, unknown> = { id };
  if (
    entityType !== "customer" &&
    entityType !== "site" &&
    entityType !== "organizationMembership" &&
    entityType !== "organizationInvitation"
  ) {
    baseVars.workspaceId = currentWorkspace?.id;
  }

  // Special case for customer and site single queries
  const variables =
    (entityType === "customer" || entityType === "site") &&
    pattern.operationName === pattern.operationName
      ? { id }
      : (pattern.variablePattern.transform(baseVars) as Record<
          string,
          unknown
        >);

  // Execute GraphQL query
  const { data, loading, error } = useQuery(query, {
    variables,
    skip:
      entityType !== "organizationMembership" &&
      entityType !== "organizationInvitation" &&
      !currentWorkspace?.id,
  });

  // Transform response data based on the pattern
  const transformedData = pattern.responsePattern.dataPath.reduce(
    (obj, path) => obj?.[path],
    data?.[pattern.operationName],
  );

  return {
    data: transformedData,
    loading,
    error,
  };
}
