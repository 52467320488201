import React from "react";
import { PrefixedBaseCell, BaseCellProps } from "@/components/edges/ui/BaseCell";
import { Icon } from "@/components/edges/ui/Icon";
import { Skeleton } from "@/components/edges/ui/Skeleton/Skeleton";
type SiteCellProps = Omit<BaseCellProps, "children" | "loadingContent"> & {
  streetOne: string;
  streetTwo: string;
  city: string;
  state: string;
  postalCode: string;
  isLoading?: boolean;
};
export const SiteCell: React.FC<SiteCellProps> = ({
  streetOne,
  streetTwo,
  city,
  state,
  postalCode,
  href,
  isPrimaryCell = false,
  isLoading,
  ...props
}) => {
  const content = <div className="flex max-w-full items-center gap-3">
      <Icon name="MapPin" size={28} aria-hidden="true" />
      <div className="flex min-h-0 flex-col" role="text" aria-label="Address">
        <div className="leading-normal">
          {`${streetOne}${streetTwo ? `, ${streetTwo}` : ""}`}
        </div>
        <div className="leading-normal">
          {`${city}, ${state} ${postalCode}`}
        </div>
      </div>
    </div>;
  const loadingContent = <div className="flex max-w-full items-center gap-3">
      <div className="flex-shrink-0">
        <Skeleton width={28} height={28} variant="circle" />
      </div>
      <div className="flex min-h-0 flex-col gap-1">
        <Skeleton width={isPrimaryCell ? 220 : 180} height={16} />
        <Skeleton width={isPrimaryCell ? 180 : 140} height={16} />
      </div>
    </div>;
  const fullAddress = `${streetOne}${streetTwo ? `, ${streetTwo}` : ""}, ${city}, ${state} ${postalCode}`;
  return <PrefixedBaseCell href={href} isPrimaryCell={isPrimaryCell} title={fullAddress} loadingContent={loadingContent} isLoading={isLoading} {...props} data-sentry-element="PrefixedBaseCell" data-sentry-component="SiteCell" data-sentry-source-file="SiteCell.tsx">
      {content}
    </PrefixedBaseCell>;
};