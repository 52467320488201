import { useQuery } from "@apollo/client";
import { buildEntityQuery } from "@/config/fields/buildEntityQuery";
import { ENTITY_FIELDS } from "@/config/fields";
import { useWorkspace } from "@/hooks/organization/useWorkspace";
import type { EntityType, FieldName } from "@/config/fields/types";
import { QUERY_PATTERNS } from "@/config/fields/queryPatterns";
import { useMemo } from "react";

// Base types for pagination and filtering
export interface PaginationOffset {
  perPage: number;
  page: number;
}

export interface PaginationInput {
  offset: PaginationOffset;
}

export interface FilterInput {
  workspaceId: string;
  [key: string]: unknown;
}

// Input variables structure
export interface EntityListDataVariables {
  filter?: Partial<FilterInput>;
  pagination?: PaginationInput;
  orderBy?: Record<string, "asc" | "desc">;
  workspaceId?: string; // New field for direct workspaceId specification
}

// Query response structure
export interface EntityListResponse<T> {
  data: T[];
  meta?: {
    totalCount: number;
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
  };
}

interface QueryResponse<T extends EntityType> {
  [key: string]: EntityListResponse<unknown>;
}

interface UseEntityListDataWithInitialProps<T extends EntityType> {
  entityType: T;
  selectedFields: FieldName<T>[];
  variables?: EntityListDataVariables;
  initialData?: unknown;
}

/**
 * Hook for fetching lists of entities with pagination, filtering, and initial data support.
 */
export function useEntityListDataWithInitial<T extends EntityType>({
  entityType,
  selectedFields,
  variables = {},
  initialData,
}: UseEntityListDataWithInitialProps<T>) {
  const { currentWorkspace } = useWorkspace();
  const query = buildEntityQuery(entityType, selectedFields);

  // Get the response path from the first list data source we find
  const responsePath = ENTITY_FIELDS[entityType]
    .find((field) => (field.dataSources ?? []).some((ds) => ds.type === "list"))
    ?.dataSources?.find((ds) => ds.type === "list")?.responsePath;

  if (!responsePath) {
    throw new Error(`No list data source found for entity type: ${entityType}`);
  }

  // Determine the workspaceId to use
  // 1. First check if there's a workspaceId in the filter
  // 2. Then check if there's a direct workspaceId in variables
  // 3. Then check for a current workspace from the new provider
  // 4. Finally fall back to selected workspace from the legacy provider
  let workspaceId: string | undefined;

  if (variables.filter?.workspaceId) {
    workspaceId = variables.filter.workspaceId as string;
  } else if (variables.workspaceId) {
    workspaceId = variables.workspaceId as string;
  } else if (currentWorkspace?.id) {
    workspaceId = currentWorkspace.id;
  }

  // Only add workspaceId to filter if not an organization membership or invitation query
  const filter =
    entityType === "organizationMembership" ||
    entityType === "organizationInvitation"
      ? variables.filter || {}
      : {
          workspaceId,
          ...(variables.filter || {}),
        };

  // Use Initial Data if provided
  const hasValidInitialData = useMemo(() => {
    if (!initialData) return false;

    // Check that initialData has the expected shape
    const typedInitialData = initialData as Record<string, unknown>;
    return (
      typeof typedInitialData === "object" &&
      "data" in typedInitialData &&
      Array.isArray(typedInitialData.data)
    );
  }, [initialData]);

  // Get the operation name from the pattern
  const operationName = QUERY_PATTERNS[entityType].list.operationName;

  // Execute GraphQL query only if we don't have initialData
  const {
    data: queryData,
    loading,
    error,
  } = useQuery<QueryResponse<T>>(query, {
    variables: {
      vars: {
        filter,
        pagination: variables.pagination,
      },
    },
    // Skip the query if we have initial data or if we need a workspace ID and don't have one
    skip:
      hasValidInitialData ||
      (entityType !== "organizationMembership" &&
        entityType !== "organizationInvitation" &&
        !workspaceId),
  });

  // Combine data sources
  const data = useMemo(() => {
    if (hasValidInitialData) {
      // Use the initial data if we have it
      return initialData as QueryResponse<T>;
    }
    return queryData;
  }, [hasValidInitialData, initialData, queryData]);

  return {
    data,
    loading: !hasValidInitialData && loading,
    error,
    totalCount: data?.[operationName]?.meta?.totalCount ?? 0,
    initialDataUsed: hasValidInitialData,
  };
}
