import { FieldDefinition } from "@/types/fields";
import { CustomerCell } from "@/components/edges/ui/CustomerCell";
import { SiteCell } from "@/components/edges/ui/SiteCell";
import { TimestampCell } from "@/components/edges/ui/TimestampCell";
import type { TimeSeriesDataSource } from "@/types/fields/dataSources/TimeSeriesDataSource";
import type { WindowSize } from "@/@codegen/supergraph";
import { MetricsQueryGroupByFacet } from "@/@codegen/supergraph";
import type { NumberFormat, NumberNotation } from "@/types/fields/formats/NumberFormat";
import type { EnergyFormat, EnergyUnit } from "@/types/fields/formats/EnergyFormat";
import { DateRange } from "@/utils/date";
export const CUSTOMER_RESPONSE_PATH = ["customersForWorkspace", "data"];

// Default to last 24 hours
const DEFAULT_DATE_RANGE: DateRange = {
  after: new Date(Date.now() - 24 * 60 * 60 * 1000),
  before: new Date()
};
export const DEFAULT_CUSTOMER_QUERY_PARAMS = {
  filter: {
    range: DEFAULT_DATE_RANGE
  }
};
const createTimeSeriesDataSource = (timeField: string, valueField: string, options: Partial<TimeSeriesDataSource<CustomerRow>> = {}): TimeSeriesDataSource<CustomerRow> => ({
  type: "timeSeries",
  entityType: "site",
  fragmentKey: "energyConsumption" as keyof CustomerRow,
  responsePath: ["data", "metrics", "timeSeries"],
  timeField,
  valueField,
  queryParams: DEFAULT_CUSTOMER_QUERY_PARAMS,
  ...options
});
export interface CustomerRow {
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  connectedSites: Array<{
    id: string;
    location: {
      streetOne: string;
      streetTwo: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  }> | null;
  name: {
    firstName: string | null;
    lastName: string | null;
  };
  site: {
    connectedSites: Array<{
      id: string;
      location: {
        streetOne: string;
        streetTwo: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    }> | null;
  };
  timestamps: {
    createdAt: string | null;
    updatedAt: string | null;
  };
  city: string;
  state: string;
  country: string;
  energyConsumption?: {
    timestamp: string;
    value: number;
    unit: "kWh";
  }[];
}
export const customerFields: FieldDefinition<CustomerRow>[] = [{
  name: "id",
  label: "Customer ID",
  dataType: "String",
  entityType: "Customer",
  format: {
    type: "text",
    transform: "lowercase"
  },
  display: {
    link: {
      type: "internal",
      getHref: (value: unknown, row: CustomerRow) => `/customers/${row.id}`
    }
  },
  tableControl: {
    getValue: (row: CustomerRow) => row.id,
    isSearchable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Customer",
    fragmentKey: "id",
    responsePath: CUSTOMER_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Customer",
    fragmentKey: "id",
    responsePath: ["customer"]
  }]
}, {
  name: "name",
  label: "Name",
  dataType: "String",
  entityType: "Customer",
  format: {
    type: "text",
    transform: "capitalize"
  },
  display: {
    link: {
      type: "internal",
      getHref: (value: unknown, row: CustomerRow) => `/customers/${row.id}`
    }
  },
  tableControl: {
    getValue: (row: CustomerRow) => {
      return `${row.firstName ?? ""} ${row.lastName ?? ""}`.trim();
    },
    render: (row: CustomerRow, {
      isLoading,
      href
    }: {
      isLoading?: boolean;
      href?: string;
    }) => {
      return <CustomerCell firstName={row.firstName ?? ""} lastName={row.lastName ?? ""} href={href} isPrimaryCell isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Customer",
    fragmentKey: "name",
    responsePath: CUSTOMER_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Customer",
    fragmentKey: "name",
    responsePath: ["customer"]
  }]
}, {
  name: "firstName",
  label: "First Name",
  dataType: "String",
  entityType: "Customer",
  format: {
    type: "text",
    transform: "capitalize",
    nullText: ""
  },
  tableControl: {
    getValue: (row: CustomerRow) => row.firstName ?? "",
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Customer",
    fragmentKey: "name",
    responsePath: CUSTOMER_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Customer",
    fragmentKey: "name",
    responsePath: ["customer"]
  }]
}, {
  name: "lastName",
  label: "Last Name",
  dataType: "String",
  entityType: "Customer",
  format: {
    type: "text",
    transform: "capitalize",
    nullText: ""
  },
  tableControl: {
    getValue: (row: CustomerRow) => row.lastName ?? "",
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Customer",
    fragmentKey: "name",
    responsePath: CUSTOMER_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Customer",
    fragmentKey: "name",
    responsePath: ["customer"]
  }]
}, {
  name: "email",
  label: "Email",
  dataType: "String",
  entityType: "Customer",
  format: {
    type: "text",
    transform: "lowercase",
    nullText: ""
  },
  display: {
    link: {
      type: "email",
      getHref: (value: unknown) => `mailto:${value as string}`
    }
  },
  tableControl: {
    getValue: (row: CustomerRow) => row.email ?? "",
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Customer",
    fragmentKey: "email",
    responsePath: CUSTOMER_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Customer",
    fragmentKey: "email",
    responsePath: ["customer"]
  }]
}, {
  name: "phone",
  label: "Phone",
  dataType: "String",
  entityType: "Customer",
  format: {
    type: "text",
    transform: "lowercase",
    nullText: ""
  },
  display: {
    link: {
      type: "phone",
      getHref: (value: unknown) => `tel:${value as string}`
    }
  },
  tableControl: {
    getValue: (row: CustomerRow) => row.phone ?? "",
    isSearchable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Customer",
    fragmentKey: "phone",
    responsePath: CUSTOMER_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Customer",
    fragmentKey: "phone",
    responsePath: ["customer"]
  }]
}, {
  name: "site",
  label: "Site",
  dataType: "String",
  entityType: "Customer",
  format: {
    type: "text",
    transform: "capitalize",
    nullText: ""
  },
  display: {
    link: {
      type: "internal",
      getHref: (value: unknown, row: CustomerRow) => {
        const site = row.connectedSites?.[0];
        return site ? `/sites/${site.id}` : "";
      }
    }
  },
  tableControl: {
    getValue: (row: CustomerRow) => {
      const site = row.connectedSites?.[0];
      return site ? [site.location.streetOne, site.location.city, site.location.state].filter(Boolean).join(", ") : "No Site";
    },
    render: (row: CustomerRow, {
      isLoading,
      href
    }: {
      isLoading?: boolean;
      href?: string;
    }) => {
      const site = row.connectedSites?.[0];
      if (!site && !isLoading) return null;
      return <SiteCell streetOne={site?.location.streetOne ?? ""} streetTwo={site?.location.streetTwo ?? ""} city={site?.location.city ?? ""} state={site?.location.state ?? ""} postalCode={site?.location.postalCode ?? ""} href={href} isLoading={isLoading} />;
    },
    isSearchable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Customer",
    fragmentKey: "site",
    responsePath: CUSTOMER_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Customer",
    fragmentKey: "site",
    responsePath: ["customer"]
  }]
}, {
  name: "createdAt",
  label: "Created",
  dataType: "String",
  entityType: "Customer",
  format: {
    type: "date",
    style: "medium",
    relative: true
  },
  tableControl: {
    getValue: (row: CustomerRow) => {
      if (!row) return "";
      return row.createdAt ?? "";
    },
    render: (row: CustomerRow, {
      isLoading
    }: {
      isLoading?: boolean;
    }) => {
      if (!row && !isLoading) return null;
      return <TimestampCell value={row?.createdAt ?? ""} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Customer",
    fragmentKey: "timestamps",
    responsePath: CUSTOMER_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Customer",
    fragmentKey: "timestamps",
    responsePath: ["customer"]
  }]
}, {
  name: "updatedAt",
  label: "Updated",
  dataType: "String",
  entityType: "Customer",
  format: {
    type: "date",
    style: "medium",
    relative: true
  },
  tableControl: {
    getValue: (row: CustomerRow) => {
      if (!row) return "";
      return row.updatedAt ?? "";
    },
    render: (row: CustomerRow, {
      isLoading
    }: {
      isLoading?: boolean;
    }) => {
      if (!row && !isLoading) return null;
      return <TimestampCell value={row?.updatedAt ?? ""} isLoading={isLoading} />;
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Customer",
    fragmentKey: "timestamps",
    responsePath: CUSTOMER_RESPONSE_PATH
  }, {
    type: "single",
    entityType: "Customer",
    fragmentKey: "timestamps",
    responsePath: ["customer"]
  }]
}, {
  name: "city",
  label: "City",
  dataType: "String",
  entityType: "Customer",
  format: {
    type: "text",
    transform: "capitalize",
    nullText: ""
  },
  tableControl: {
    getValue: (row: CustomerRow) => {
      const site = row.connectedSites?.[0];
      return site?.location.city ?? "";
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Customer",
    fragmentKey: "site",
    responsePath: CUSTOMER_RESPONSE_PATH
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct",
    getValue: (row: CustomerRow) => {
      const site = row.connectedSites?.[0];
      return site?.location.city ?? "";
    }
  }]
}, {
  name: "state",
  label: "State",
  dataType: "String",
  entityType: "Customer",
  format: {
    type: "text",
    transform: "uppercase",
    nullText: ""
  },
  tableControl: {
    getValue: (row: CustomerRow) => {
      const site = row.connectedSites?.[0];
      return site?.location.state ?? "";
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Customer",
    fragmentKey: "site",
    responsePath: CUSTOMER_RESPONSE_PATH
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct",
    getValue: (row: CustomerRow) => {
      const site = row.connectedSites?.[0];
      return site?.location.state ?? "";
    }
  }]
}, {
  name: "country",
  label: "Country",
  dataType: "String",
  entityType: "Customer",
  format: {
    type: "text",
    transform: "capitalize",
    nullText: ""
  },
  tableControl: {
    getValue: (row: CustomerRow) => {
      const site = row.connectedSites?.[0];
      return site?.location.country ?? "";
    },
    isSearchable: true,
    isSortable: true
  },
  dataSources: [{
    type: "list",
    entityType: "Customer",
    fragmentKey: "site",
    responsePath: CUSTOMER_RESPONSE_PATH
  }],
  filters: [{
    type: "facet",
    execution: "client",
    computeValues: "distinct",
    getValue: (row: CustomerRow) => {
      const site = row.connectedSites?.[0];
      return site?.location.country ?? "";
    }
  }]
}, {
  name: "energyConsumption",
  label: "Energy Consumption",
  dataType: "Number",
  entityType: "customer",
  dataSources: [createTimeSeriesDataSource("timestamp", "value", {
    fragmentKey: "energyConsumption",
    queryParams: {
      ...DEFAULT_CUSTOMER_QUERY_PARAMS,
      filter: {
        type: "consumption",
        range: DEFAULT_DATE_RANGE
      }
    }
  })],
  format: {
    type: "energy",
    preferredUnit: "kWh" as EnergyUnit,
    notation: "standard" as NumberNotation,
    precision: 2
  } as EnergyFormat
}];