import { useState, useEffect } from "react";

type LoadingStage = "auth" | "org" | "workspace" | "complete";

export const useLoadingSequence = (
  authLoading: boolean,
  orgLoading: boolean,
  workspaceLoading: boolean,
) => {
  const [stage, setStage] = useState<LoadingStage>("auth");
  const [message, setMessage] = useState("Authenticating...");
  const [shouldShow, setShouldShow] = useState(true);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const updateStage = () => {
      if (authLoading) {
        setStage("auth");
        setMessage("Authenticating...");
        setShouldShow(true);
      } else if (orgLoading) {
        setStage("org");
        setMessage("Loading organization...");
        setShouldShow(true);
      } else if (workspaceLoading) {
        setStage("workspace");
        setMessage("Loading workspace...");
        setShouldShow(true);
      } else {
        setStage("complete");
        // Add a slight delay before hiding the loading screen to prevent flashing
        timeout = setTimeout(() => {
          setShouldShow(false);
        }, 300);
      }
    };

    updateStage();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [authLoading, orgLoading, workspaceLoading]);

  return {
    stage,
    message,
    shouldShow,
  };
};
