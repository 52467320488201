import { BaseCell, BaseCellProps } from "./BaseCell";
import { UrlPrefixContext } from "@/utils/urlContext";
import { useContext } from "react";

// Enhanced version of BaseCell that applies URL prefixing
export const PrefixedBaseCell: React.FC<BaseCellProps> = props => {
  const urlPrefix = useContext(UrlPrefixContext);
  if (!urlPrefix || !props.href) {
    return <BaseCell {...props} />;
  }

  // Apply prefix to href if it's a relative URL and doesn't already have the prefix
  let prefixedHref = props.href;
  if (props.href.startsWith("/") && !props.href.startsWith(urlPrefix)) {
    prefixedHref = `${urlPrefix}${props.href}`;
  }
  return <BaseCell {...props} href={prefixedHref} data-sentry-element="BaseCell" data-sentry-component="PrefixedBaseCell" data-sentry-source-file="PrefixedBaseCell.tsx" />;
};