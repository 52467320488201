import { gql, DocumentNode } from "@apollo/client";
import { CUSTOMER_FRAGMENTS } from "./fragments/customerFragments";
import { SITE_FRAGMENTS } from "./fragments/siteFragments";
import { LEAD_FRAGMENTS } from "./fragments/leadFragments";
import { DEVICE_FRAGMENTS } from "./fragments/deviceFragments";
import { MEMBERSHIP_FRAGMENTS } from "./fragments/membershipFragments";
import { INVITATION_FRAGMENTS } from "./fragments/invitationFragments";
import { LOCATION_FRAGMENTS } from "./fragments/locationFragments";
import { COLLECTION_FRAGMENTS } from "./fragments/collectionFragments";
import { TIME_SERIES_FRAGMENTS } from "./fragments/timeSeriesFragments";
import { ENTITY_FIELDS } from "./index";
import { FieldDefinition } from "@/types/fields";
import { BaseDataSource } from "@/types/fields";
import { QUERY_PATTERNS, QueryPatternType } from "./queryPatterns";
import type { EntityType } from "./types";

// Map of entity types to their fragments
const ENTITY_FRAGMENTS = {
  customer: CUSTOMER_FRAGMENTS,
  site: SITE_FRAGMENTS,
  lead: LEAD_FRAGMENTS,
  device: DEVICE_FRAGMENTS,
  organizationMembership: MEMBERSHIP_FRAGMENTS,
  organizationInvitation: INVITATION_FRAGMENTS,
  collection: COLLECTION_FRAGMENTS,
} as const;

// Map of shared fragments that can be used across entities
const SHARED_FRAGMENTS = {
  location: LOCATION_FRAGMENTS,
} as const;

/**
 * Builds a GraphQL query dynamically based on requested fields.
 *
 * @param entityType - The entity type (e.g., "customer").
 * @param selectedFields - An array of field names to include in the query.
 * @param queryType - The type of query to build ("list", "single", or "timeSeries").
 * @returns A dynamically generated GraphQL query document.
 */
export function buildEntityQuery<
  T extends Record<string, unknown> & { id: string },
>(
  entityType: EntityType,
  selectedFields: Array<keyof T>,
  queryType: QueryPatternType = "list",
) {
  if (!Object.hasOwn(ENTITY_FIELDS, entityType)) {
    throw new Error(`Unknown entity type: ${String(entityType)}`);
  }

  const entityFields = ENTITY_FIELDS[
    entityType
  ] as unknown as FieldDefinition<T>[];
  const entityFragments = ENTITY_FRAGMENTS[entityType];
  const pattern = QUERY_PATTERNS[entityType][queryType];

  if (!pattern) {
    throw new Error(
      `Query pattern not found for ${queryType} on entity type ${entityType}`,
    );
  }

  // For time series queries, we use the standardized fragments
  if (queryType === "timeSeries") {
    return gql`
      query GetTimeSeriesData($input: MetricQueryInput!) {
        ${pattern.operationName}(input: $input) {
          ...TimeSeriesComplete
        }
      }
      ${TIME_SERIES_FRAGMENTS.complete}
    `;
  }

  // For command history queries, we use the command history fragment
  if (queryType === "commands") {
    console.log("[Command Query Debug] Initial state:", {
      entityType,
      queryType,
      selectedFields,
      hasDeviceFragments: !!DEVICE_FRAGMENTS,
      deviceFragmentKeys: Object.keys(DEVICE_FRAGMENTS),
    });

    // Get the fragment definition
    const fragment = DEVICE_FRAGMENTS.commandHistory;
    console.log("[Command Query Debug] Fragment details:", {
      hasFragment: !!fragment,
      fragmentType: typeof fragment,
      fragmentDefinitions: fragment?.definitions?.map((def) => ({
        kind: def.kind,
        name: "name" in def ? def.name?.value : "no name",
        type:
          def.kind === "FragmentDefinition"
            ? def.typeCondition?.name?.value
            : "not a fragment",
      })),
      rawFragment: fragment?.loc?.source?.body,
    });

    if (!fragment?.definitions?.[0]) {
      throw new Error("Command history fragment not found");
    }

    // Get the fragment name from the definition
    const fragmentDef = fragment.definitions[0];
    console.log("[Command Query Debug] Fragment definition:", {
      kind: fragmentDef.kind,
      hasName: "name" in fragmentDef,
      name: "name" in fragmentDef ? fragmentDef.name?.value : "no name",
    });

    if (!("name" in fragmentDef) || !fragmentDef.name) {
      throw new Error("Invalid fragment definition");
    }

    const fragmentName = fragmentDef.name.value;
    const query = gql`
      ${fragment}
      query GetDeviceCommands($input: DeviceCommandsInput!) {
        deviceCommands(input: $input) {
          ...${fragmentName}
        }
      }
    `;

    console.log("[Command Query Debug] Final query:", {
      fragmentName,
      queryString: query?.loc?.source?.body,
      definitions: query?.definitions?.map((def) => ({
        kind: def.kind,
        name: "name" in def ? def.name?.value : "no name",
      })),
    });

    return query;
  }

  // Ensure selectedFields exist in entityFields
  const missingFields = selectedFields.filter(
    (field) => !entityFields.some((def) => def.name === field),
  );

  if (missingFields.length > 0) {
    throw new Error(
      `Fields missing from entity definition: ${missingFields.map(String).join(", ")}`,
    );
  }

  // Extract only the relevant fields
  const fieldDefs = entityFields.filter((field) =>
    selectedFields.includes(field.name),
  );

  // Ensure every selected field has a fragment
  const missingFragments = selectedFields.filter((field) => {
    const fieldDef = entityFields.find((def) => def.name === field);
    if (!fieldDef) return false;

    // Check if any data source has a fragmentKey
    return !fieldDef.dataSources?.some((ds) => {
      // For command queries, check if the fragment exists in DEVICE_FRAGMENTS
      if (ds.type === "commands") {
        const fragmentKey = ds.fragmentKey;
        if (!fragmentKey) return false;
        return fragmentKey in DEVICE_FRAGMENTS;
      }

      if (ds.type !== queryType) return false;
      const fragmentKey = ds.fragmentKey;
      if (!fragmentKey) return false;

      // Check if fragment exists in entity fragments or shared fragments
      const hasEntityFragment = fragmentKey in entityFragments;
      const hasSharedFragment = Object.values(SHARED_FRAGMENTS).some(
        (group) => fragmentKey in group,
      );

      return hasEntityFragment || hasSharedFragment;
    });
  });

  if (missingFragments.length > 0) {
    throw new Error(
      `No valid fragment found for fields: ${missingFragments.map(String).join(", ")}`,
    );
  }

  // Extract unique fragment keys from data sources
  const fragmentKeys = Array.from(
    new Set(
      fieldDefs.flatMap((field) => {
        const sources = field.dataSources ?? [];
        // Find all data sources of the specified type for this field
        return sources
          .filter(
            (ds): ds is BaseDataSource<T> & { type: typeof queryType } =>
              ds.type === queryType,
          )
          .map((ds) => ds.fragmentKey)
          .filter(Boolean);
      }),
    ),
  );

  // Collect all fragments, including shared ones
  const fragments = fragmentKeys
    .map((key) => {
      if (!key) return null;
      // First check entity-specific fragments
      let fragment = entityFragments[key as keyof typeof entityFragments];

      // If not found, check shared fragments
      if (!fragment) {
        const sharedFragmentGroup = Object.values(SHARED_FRAGMENTS).find(
          (group) => key in group,
        );
        if (sharedFragmentGroup) {
          fragment =
            sharedFragmentGroup[key as keyof typeof sharedFragmentGroup];
        }
      }

      if (!fragment) {
        console.warn(
          `Fragment not found for key "${String(key)}" in ${String(entityType)} fragments or shared fragments`,
        );
        return null;
      }
      return fragment;
    })
    .filter((frag): frag is DocumentNode => frag !== null);

  // Construct the fragment spreads dynamically
  const fragmentSpreads = fragments
    .map((frag) => {
      const def = frag.definitions[0];
      if (!("name" in def) || !def.name) return "";
      return `...${def.name.value}`;
    })
    .filter(Boolean)
    .join("\n");

  // Wrap selections based on the response pattern
  const wrapSelections = (selections: string, path: string[]): string => {
    return path.reduceRight((acc, curr) => `${curr} { ${acc} }`, selections);
  };

  const selections = wrapSelections(
    fragmentSpreads,
    pattern.responsePattern.dataPath,
  );
  const metaSection = pattern.responsePattern.includesMeta
    ? "meta { totalCount }"
    : "";

  // Build the query using the pattern
  return gql`
    query Get${entityType.charAt(0).toUpperCase() + entityType.slice(1)}Data(${
      (entityType === "customer" || entityType === "site") &&
      queryType === "single"
        ? "$id: ID!"
        : "$vars: " + pattern.variablePattern.type
    }) {
      ${pattern.operationName}${
        (entityType === "customer" || entityType === "site") &&
        queryType === "single"
          ? "(id: $id)"
          : "(input: $vars)"
      } {
        ${selections}
        ${metaSection}
      }
    }
    ${fragments.map((frag) => frag.loc?.source.body ?? "").join("\n")}
  `;
}
