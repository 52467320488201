import { FormatRegistry } from "@/utils/string";
import {
  type FieldFormat,
  type FieldValue,
  type FormattedValue,
  type TextFormat,
  type NumberFormat,
  type CurrencyFormat,
  type DateFormat,
  type BooleanFormat,
  type EnergyFormat,
  type TemperatureFormat,
  type DistanceFormat,
  type PhoneFormat,
  type CustomFormat,
  type EnergyUnit,
  type DistanceUnit,
} from "@/types/fields";

export const textTransforms = {
  uppercase: (text: string | null | undefined) => text?.toUpperCase() ?? "",
  lowercase: (text: string | null | undefined) => text?.toLowerCase() ?? "",
  capitalize: (text: string) =>
    FormatRegistry.format("titleCase", text) ?? text,
  titleCase: (text: string) => FormatRegistry.format("titleCase", text) ?? text,
  sentenceCase: (text: string) =>
    text ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase() : "",
} as const;

export type TextTransform = keyof typeof textTransforms;

export enum TemperatureScaleEnum {
  F = "F",
  C = "C",
  K = "K",
}

// Helper to check if a value should be hidden
const shouldHideValue = (value: FieldValue, format: FieldFormat): boolean => {
  if (
    format.hideIfEmpty &&
    (value === null || value === undefined || value === "")
  ) {
    return true;
  }
  if (format.condition && !format.condition(value)) {
    return true;
  }
  return false;
};

// Text formatting functions
const formatText = (value: FieldValue, format: TextFormat): FormattedValue => {
  if (shouldHideValue(value, format)) return null;
  if (value == null) return format.nullText ?? null;

  let formatted = String(value);

  // Apply transforms
  if (format.transform) {
    const transforms = Array.isArray(format.transform)
      ? format.transform
      : [format.transform];
    transforms.forEach((transform) => {
      switch (transform) {
        case "uppercase":
          formatted = formatted.toUpperCase();
          break;
        case "lowercase":
          formatted = formatted.toLowerCase();
          break;
        case "capitalize":
          formatted =
            FormatRegistry.format("titleCase", formatted) ?? formatted;
          break;
        case "titleCase":
          formatted =
            FormatRegistry.format("titleCase", formatted) ?? formatted;
          break;
        case "sentenceCase":
          formatted =
            formatted.charAt(0).toUpperCase() +
            formatted.slice(1).toLowerCase();
          break;
      }
    });
  }

  // Apply truncation
  if (format.truncate) {
    const { length, position, ellipsis = "..." } = format.truncate;
    if (formatted.length > length) {
      switch (position) {
        case "start":
          formatted = ellipsis + formatted.slice(-length);
          break;
        case "middle": {
          const startLength = Math.ceil((length - ellipsis.length) / 2);
          const endLength = Math.floor((length - ellipsis.length) / 2);
          formatted =
            formatted.slice(0, startLength) +
            ellipsis +
            formatted.slice(-endLength);
          break;
        }
        case "end":
          formatted = formatted.slice(0, length) + ellipsis;
          break;
      }
    }
  }

  // Apply highlighting
  if (format.highlight?.pattern) {
    const pattern =
      format.highlight.pattern instanceof RegExp
        ? format.highlight.pattern
        : new RegExp(format.highlight.pattern, "g");
    formatted = formatted.replace(
      pattern,
      (match) =>
        `<span class="${format.highlight?.className ?? "highlight"}">${match}</span>`,
    );
  }

  return formatted;
};

// Number formatting functions
const formatNumber = (
  value: FieldValue,
  format: NumberFormat,
): FormattedValue => {
  if (shouldHideValue(value, format)) return null;
  if (value == null) return format.nullText ?? null;
  if (typeof value !== "number") return String(value);

  // Scale the value based on the unit
  let scaledValue = value;
  if (format.unit) {
    switch (format.unit) {
      case "kW":
      case "kWh":
        scaledValue = value / 1000;
        break;
      case "MW":
      case "MWh":
        scaledValue = value / 1000000;
        break;
      default:
        scaledValue = value;
    }
  }

  const options: Intl.NumberFormatOptions = {
    style: format.style === "percent" ? "percent" : "decimal",
    notation: format.notation,
    useGrouping: format.grouping,
  };

  if (format.precision) {
    if (format.precision.exact !== undefined) {
      options.minimumFractionDigits = format.precision.exact;
      options.maximumFractionDigits = format.precision.exact;
    } else {
      if (format.precision.min !== undefined)
        options.minimumFractionDigits = format.precision.min;
      if (format.precision.max !== undefined)
        options.maximumFractionDigits = format.precision.max;
    }
  }

  const formatted = new Intl.NumberFormat(format.locale, options).format(
    scaledValue,
  );
  return format.showUnit && format.unit
    ? `${formatted} ${format.unit}`
    : formatted;
};

// Currency formatting functions
const formatCurrency = (
  value: FieldValue,
  format: CurrencyFormat,
): FormattedValue => {
  if (shouldHideValue(value, format)) return null;
  if (value == null) return format.nullText ?? null;
  if (typeof value !== "number") return String(value);

  return new Intl.NumberFormat(format.locale, {
    style: "currency",
    currency: format.currency,
    notation: format.notation,
    signDisplay: format.signDisplay,
    minimumFractionDigits: format.minimumFractionDigits,
    maximumFractionDigits: format.maximumFractionDigits,
  }).format(value);
};

// Date formatting functions
const formatDate = (value: FieldValue, format: DateFormat): FormattedValue => {
  if (shouldHideValue(value, format)) return null;
  if (value == null) return format.nullText ?? null;

  // Type narrowing for date construction
  if (typeof value === "boolean") return String(value);

  const date = value instanceof Date ? value : new Date(value);
  if (isNaN(date.getTime())) return String(value);

  if (format.relative) {
    return FormatRegistry.format("relativeTime", date);
  }

  const options: Intl.DateTimeFormatOptions = {
    dateStyle: format.style,
    timeStyle: format.showTime ? format.style : undefined,
    calendar: format.calendar,
    timeZone: format.timeZone,
  };

  return new Intl.DateTimeFormat(format.locale, options).format(date);
};

// Boolean formatting functions
const formatBoolean = (
  value: FieldValue,
  format: BooleanFormat,
): FormattedValue => {
  if (shouldHideValue(value, format)) return null;
  if (value == null) return format.nullText ?? null;

  const boolValue = Boolean(value);

  switch (format.style) {
    case "text":
      return format.labels?.[boolValue ? "true" : "false"] ?? String(boolValue);
    case "icon":
      return format.icons?.[boolValue ? "true" : "false"] ?? String(boolValue);
    case "color":
      return format.colors?.[boolValue ? "true" : "false"] ?? String(boolValue);
    case "badge":
      return format.labels?.[boolValue ? "true" : "false"] ?? String(boolValue);
    default:
      return String(boolValue);
  }
};

// Energy formatting functions
const formatEnergy = (
  value: FieldValue,
  format: EnergyFormat,
): FormattedValue => {
  if (shouldHideValue(value, format)) return null;
  if (value == null) return format.nullText ?? null;
  if (typeof value !== "number") return String(value);

  let unit = format.preferredUnit;
  let scaledValue = value;

  if (format.autoScale && format.scaleThresholds) {
    const thresholds = Object.entries(format.scaleThresholds).sort(
      ([, a], [, b]) => (a as number) - (b as number),
    );

    for (const [u, threshold] of thresholds) {
      if (Math.abs(value) < (threshold as number)) {
        unit = u as EnergyUnit;
        break;
      }
    }

    // Scale the value based on the unit
    switch (unit) {
      case "kW":
      case "kWh":
        scaledValue = value / 1000;
        break;
      case "MW":
      case "MWh":
        scaledValue = value / 1000000;
        break;
    }
  }

  const formatted = new Intl.NumberFormat(format.locale, {
    maximumFractionDigits: format.precision ?? 2,
    notation: format.notation,
  }).format(scaledValue);

  return format.showUnit ? `${formatted} ${unit}` : formatted;
};

// Temperature formatting functions
const formatTemperature = (
  value: FieldValue,
  format: TemperatureFormat,
): FormattedValue => {
  if (shouldHideValue(value, format)) return null;
  if (value == null) return format.nullText ?? null;
  if (typeof value !== "number") return String(value);

  let convertedValue = value;
  const targetScale = format.convertTo ?? format.scale;

  // Convert to target scale if needed
  if (format.scale !== targetScale) {
    if (
      format.scale === TemperatureScaleEnum.F &&
      targetScale === TemperatureScaleEnum.C
    ) {
      convertedValue = (value - 32) * (5 / 9);
    } else if (
      format.scale === TemperatureScaleEnum.C &&
      targetScale === TemperatureScaleEnum.F
    ) {
      convertedValue = value * (9 / 5) + 32;
    } else if (
      format.scale === TemperatureScaleEnum.K &&
      targetScale === TemperatureScaleEnum.C
    ) {
      convertedValue = value - 273.15;
    } else if (
      format.scale === TemperatureScaleEnum.C &&
      targetScale === TemperatureScaleEnum.K
    ) {
      convertedValue = value + 273.15;
    }
  }

  if (!format.allowNegative) {
    convertedValue = Math.max(0, convertedValue);
  }

  const formatted = new Intl.NumberFormat(format.locale, {
    maximumFractionDigits: format.precision ?? 1,
    notation: format.notation,
  }).format(convertedValue);

  return format.showScale ? `${formatted}°${targetScale}` : formatted;
};

// Distance formatting functions
const formatDistance = (
  value: FieldValue,
  format: DistanceFormat,
): FormattedValue => {
  if (shouldHideValue(value, format)) return null;
  if (value == null) return format.nullText ?? null;
  if (typeof value !== "number") return String(value);

  let unit = format.preferredUnit ?? format.unit;
  let scaledValue = value;

  if (format.autoScale && format.scaleThresholds) {
    const thresholds = Object.entries(format.scaleThresholds).sort(
      ([, a], [, b]) => (a as number) - (b as number),
    );

    for (const [u, threshold] of thresholds) {
      if (Math.abs(value) < (threshold as number)) {
        unit = u as DistanceUnit;
        break;
      }
    }
  }

  // Convert to target unit if needed
  if (format.unit !== unit) {
    const conversions: Record<string, number> = {
      mi_to_km: 1.60934,
      km_to_mi: 0.621371,
      m_to_km: 0.001,
      km_to_m: 1000,
      ft_to_m: 0.3048,
      m_to_ft: 3.28084,
      yd_to_m: 0.9144,
      m_to_yd: 1.09361,
    };

    const conversionKey = `${format.unit}_to_${unit}`;
    if (conversions[conversionKey]) {
      scaledValue *= conversions[conversionKey];
    }
  }

  const formatted = new Intl.NumberFormat(format.locale, {
    maximumFractionDigits: format.precision ?? 2,
    notation: format.notation,
  }).format(scaledValue);

  return format.showUnit ? `${formatted} ${unit}` : formatted;
};

// Phone formatting functions
const formatPhone = (
  value: FieldValue,
  format: PhoneFormat,
): FormattedValue => {
  if (shouldHideValue(value, format)) return null;
  if (value == null) return format.nullText ?? null;

  const phone = String(value);

  if (format.validate) {
    // Basic phone validation - can be enhanced
    const isValid = /^\+?[\d\s-()]+$/.test(phone);
    if (!isValid) return phone;
  }

  return FormatRegistry.format("phoneNumber", phone);
};

// Custom formatting function
const formatCustom = (
  value: FieldValue,
  format: CustomFormat,
): FormattedValue => {
  if (shouldHideValue(value, format)) return null;
  if (value == null) return format.nullText ?? null;

  return format.formatter(value, format.options);
};

// Main formatting function
export const formatFieldValue = (
  value: FieldValue,
  format: FieldFormat,
): FormattedValue => {
  switch (format.type) {
    case "text":
      return formatText(value, format);
    case "number":
      return formatNumber(value, format);
    case "currency":
      return formatCurrency(value, format);
    case "date":
      return formatDate(value, format);
    case "boolean":
      return formatBoolean(value, format);
    case "energy":
      return formatEnergy(value, format);
    case "temperature":
      return formatTemperature(value, format);
    case "distance":
      return formatDistance(value, format);
    case "phone":
      return formatPhone(value, format);
    case "custom":
      return formatCustom(value, format);
    default:
      return String(value);
  }
};
