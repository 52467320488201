import { customerFields } from "./customerFields";
import { siteFields } from "./siteFields";
import { leadFields } from "./leadFields";
import { deviceFields } from "./deviceFields";
import { organizationMembersFields } from "./organizationMembersFields";
import { organizationInvitationsFields } from "./organizationInvitationsFields";
import { collectionFields } from "./collectionFields";
// 🔹 Unified Export
export const ENTITY_FIELDS = {
  customer: customerFields,
  site: siteFields,
  lead: leadFields,
  device: deviceFields,
  organizationMembership: organizationMembersFields,
  organizationInvitation: organizationInvitationsFields,
  collection: collectionFields,
} as const;

export type EntityType = keyof typeof ENTITY_FIELDS;
