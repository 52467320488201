import { gql } from "@apollo/client";
import { LOCATION_FRAGMENTS } from "./locationFragments";

/**
 * 🔹 Site GraphQL Fragments
 * - These fragments define reusable field selections for sites.
 * - Used to ensure that only necessary data is fetched dynamically.
 */

export const SITE_FRAGMENTS = {
  id: gql`
    fragment SiteIdFields on Site {
      id
    }
  `,
  location: gql`
    fragment SiteLocationFields on Site {
      id
      location {
        ...LocationFields
      }
    }
    ${LOCATION_FRAGMENTS.location}
  `,
  customer: gql`
    fragment SiteCustomerFields on Site {
      id
      connectedCustomers {
        id
        firstName
        lastName
      }
    }
  `,
  timestamps: gql`
    fragment SiteTimestampFields on Site {
      id
      createdAt
      updatedAt
    }
  `,
};
