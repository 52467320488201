import Link from "next/link";
import { ReactNode } from "react";
interface TextLinkProps {
  href?: string;
  children: ReactNode;
  className?: string;
  external?: boolean;
  title?: string;
  variant?: "default" | "primary" | "muted";
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  asButton?: boolean;
  onPress?: () => void;
}
export const TextLink = ({
  href,
  children,
  className = "",
  external = false,
  title,
  variant = "default",
  onClick,
  asButton = false,
  onPress
}: TextLinkProps) => {
  const externalProps = external ? {
    target: "_blank",
    rel: "noopener noreferrer"
  } : {};
  const baseClasses = "hover:underline hover:decoration-1 hover:underline-offset-4 cursor-pointer";
  const variantClasses = {
    default: "text-text-link-default hover:text-text-link-hover visited:text-text-link-default",
    primary: "text-action-primary hover:text-action-primary-hover visited:text-action-primary",
    muted: "text-text-muted hover:text-text-muted visited:text-text-muted"
  };
  const combinedClasses = `${baseClasses} ${variantClasses[variant]} ${className}`;
  if (asButton) {
    return <button type="button" className={combinedClasses} onClick={onPress} title={title}>
        {children}
      </button>;
  }
  return <Link href={href || "#"} className={combinedClasses} {...externalProps} title={title} role="link" onClick={onClick} data-sentry-element="Link" data-sentry-component="TextLink" data-sentry-source-file="TextLink.tsx">
      {children}
    </Link>;
};
export default TextLink;