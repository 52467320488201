import React from "react";
import { PrefixedBaseCell, BaseCellProps } from "../BaseCell";
import { CellAlignment } from "../DataTable";
import { toRelativeTime } from "@/utils/date";
type TimestampCellProps = Omit<BaseCellProps, "children"> & {
  value: string | null | undefined;
  align?: CellAlignment;
};
export const TimestampCell: React.FC<TimestampCellProps> = ({
  value,
  align,
  ...props
}) => {
  return <PrefixedBaseCell align={align} {...props} data-sentry-element="PrefixedBaseCell" data-sentry-component="TimestampCell" data-sentry-source-file="TimestampCell.tsx">
      {value ? toRelativeTime(value) : "—"}
    </PrefixedBaseCell>;
};