import React, { createContext, useContext } from "react";

// Context to share URL prefix information throughout the component tree
export const UrlPrefixContext = createContext<string | undefined>(undefined);

// Provider component
export const UrlPrefixProvider: React.FC<{
  prefix: string;
  children: React.ReactNode;
}> = ({
  prefix,
  children
}) => {
  return <UrlPrefixContext.Provider value={prefix} data-sentry-element="unknown" data-sentry-component="UrlPrefixProvider" data-sentry-source-file="urlContext.tsx">
      {children}
    </UrlPrefixContext.Provider>;
};

// Hook to access the URL prefix
export const useUrlPrefix = () => {
  return useContext(UrlPrefixContext);
};

// Utility function to apply prefix to a URL if available from context
export const withPrefix = (url: string | undefined): string | undefined => {
  if (!url) return url;
  const prefix = useContext(UrlPrefixContext);
  if (!prefix) return url;

  // Avoid double prefixing
  if (url.startsWith(prefix)) return url;

  // Apply prefix to relative URLs only (those starting with /)
  if (url.startsWith("/")) {
    return `${prefix}${url}`;
  }
  return url;
};